import { Carousel } from '@mantine/carousel';
import { useMediaQuery } from '@mantine/hooks';
import { Paper, Text, Title, Button, useMantineTheme, rem } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../language/Language';

import useSWR from "swr"
import { fetcher, Http } from "../Fetcher"
import { ContentItem, PageItem } from "./api/Content"


interface CardProps {
  image: string;
  title: string;
  category: string;
  url: string
}

function Card({ image, title, category, url }: CardProps) {
  const lang = useLanguage();
  let navigate = useNavigate();
  
  return (
    <Paper onClick={() => navigate(url)}
    //   shadow="md"
      p="xl"
      radius={0}
      style={{ backgroundImage: `url(${image})` }}
      className={" saturate-25 hover:saturate-150 cursor-pointer"}
      h={450}
    >
      <div>
        <Text className={"text-white my-2"} size="sm">
          {category}
        </Text>
        <Title order={2} className={"text-white  backdrop-blur backdrop-brightness-75 p-2 my-2"}>
          {title}
        </Title>
      </div>
        <Button size='md' variant='outline' color="gray" className='text-white backdrop-blur backdrop-brightness-85 mt-4' radius={'xl'} 
        onClick={() => navigate(url)}
        >
         {lang.Text('Read more')} <FontAwesomeIcon className='ml-2' icon={duotone('arrow-right')} color="white" size="1x" />
        </Button>
    </Paper>
  );
}

export function News() {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const lang = useLanguage();
  
  const { data, isLoading } = useSWR<ContentItem[]>(
    () =>  '/api/content/getcontentbytype?contentType=news&locale=' + lang.locale
        + '&preview=false'
   , fetcher, {
        refreshInterval: 0, 
        keepPreviousData: true,
        onError:  (error: any) => {
            console.error(error);
        }
    });

  const slides = () => {
    if (data!== undefined)
      return data.map((item) => (
        <Carousel.Slide key={item.title}>
          <Card image={item.media?.url} title={item.caption} category={item.shortDescription} url={item.url} />
        </Carousel.Slide>
      ));
    else
    return <></>
  }
  
  return (
    <>
    {isLoading ? <div>{lang.Text('Loading...')}</div> :
    <Carousel
      slideSize="33%"
      height={450}
      // breakpoints={[{ maxWidth: 'sm', slideSize: '100%', slideGap: rem(0) }]}
      slideGap={0}
      withIndicators={data?.length ? data?.length > 2 : false}
      controlsOffset="lg"
      loop
      controlSize={40}
      withControls={data?.length ? data?.length > 3 : false || mobile}
      nextControlIcon={<FontAwesomeIcon icon={duotone('arrow-right')} color="black" size="1x" />}
      previousControlIcon={<FontAwesomeIcon icon={duotone('arrow-left')} color="black" size="1x" />}
      align="start"
      slidesToScroll={mobile ? 1 : 2}
    >
      {slides()}
    </Carousel>}
    </>
  );
}