import { TypographyStylesProvider } from "@mantine/core";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import remarkMdx from "remark-mdx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";


export const renderMarkdown = (markdown: any, key?: string, className?: any, linkcolor?: any) => {
    return(<>
       {markdown ?
       <TypographyStylesProvider p={0}>
           <ReactMarkdown className={className} key={key} 
                children={markdown}  
                remarkPlugins={[remarkGfm, remarkBreaks]}
                components={{
                    // li: ({node, ...props}) => {
                    //     return <List.Item className={className} {...props}/>;
                    // },
                    // ul: ({node, ...props}) => {
                    //     return <List {...props} icon={<FontAwesomeIcon color="#0476d9" size="1x" icon={duotone('check-circle')}/>}/>;
                    // },
                    li: ({node, ...props}) => {
                        return <li className="list-disc" {...props}/>;
                    },
                    ul: ({node, ...props}) => {
                        return <ul {...props} ></ul>;
                    },
                    a: ({node, ...props}) => {
                        return <a {...props} target="_blank" rel="noreferrer" style={{color:linkcolor, textDecoration: 'underline'}} >{props.children}</a>;
                    }
                }}
            />
       </TypographyStylesProvider>:<></>}
       </>);
   }