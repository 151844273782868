import * as React from 'react';
import { useContext} from 'react';

// Packages imports
import * as App from 'pubsub-js';
import 'moment/locale/se';
import * as moment from "moment";

// Backbone imports
import { GlobalAppsettings, StorageKeys } from '../../GlobalSettings';
import { KeyWord, LanguageInfo } from '../api/Language';
import { ResultData } from '../api/common';
import { Http, fetcher } from '../Fetcher';


export interface LanguageState {
    current?: LanguageInfo;
}

export class Language {
    
    constructor() {
        var cache = localStorage.getItem(StorageKeys.APP_LANGUAGE);
        if (cache != null) {
            this.text = JSON.parse(cache);
        }
        else {
            console.info('No Language Cache');
        }
    }
    public static GetLocale(): string {
        let storedLocale = localStorage.getItem(StorageKeys.APP_CURRENT_LANGUAGE);
        
        if (storedLocale != null) {
            return storedLocale;
        }
        else
        {
            return "default";
        }

        // let locale = moment.locale();
        // if (locale === 'en') locale = 'en-GB';
        // if (locale === 'se') locale = 'sv';
        // console.info("Locale ->" + locale);

        // return locale;
    }
    private text?: KeyWord[];
    private id?: string;
    private langid?: string;

    public UnMapped(): KeyWord[] {
        return Language._unmapped;
    }

    public static ResetUnMapped() {
        Language._unmapped = [];
    }
   
    public locale() {
        return Language.GetLocale();
    }

    private static _unmapped: KeyWord[] = [];

    public loadData(data: KeyWord[]) {
        this.text = data;
        localStorage.setItem(StorageKeys.APP_LANGUAGE, JSON.stringify(this.text));
    }

    private find(data: KeyWord[], search: string) {
        let val: string = search;
        if (search !== undefined && search !== null) {
            let found = false;
            data.forEach((element) => {
                if (element.key.toLowerCase() === search.toLowerCase()) {
                    val = element.value;
                    found = true;
                    return val;
                }
            });
            if (!found) {
                // if (GlobalAppsettings.IsDevelopment) {
                    let found_unmapped = false;
                    Language._unmapped.forEach((element) => {
                        if (element.key.toLowerCase() === search.toLowerCase()) {
                            val = element.value;
                            found_unmapped = true;
                        }
                    });
                    if (!found_unmapped)
                        Language._unmapped.push({ key: search, value: search });
                // }
            }
        }
        else {
            return 'empty.value';
        }
        return val;
    }

    public Text(key?: string): string {
        if (this.text !== undefined && key !== undefined) {
            return this.find(this.text, key);
        }
        return "-"; //Default
    }

    public _text(key: string): React.ReactElement | string {
        if (this.text !== undefined) {
            return this.find(this.text, key);
        }
        return "-"; //Default
    }
}

export const LanguageContext = React.createContext<Language>(new Language());

export const LanguageConsumer = LanguageContext.Consumer;

export const useLanguage = () => useContext(LanguageContext);

export interface LanguageProviderState {
    current: Language,
    edit_lang: boolean
}

export interface LanguageProviderProps {
    children: any
}

export class LanguageProvider extends React.Component<LanguageProviderProps, LanguageProviderState> {
    constructor(props: LanguageProviderProps) {
        super(props);
        let cl: Language = new Language();
        this.state = { current: cl, edit_lang: false };
    }

    componentDidMount() {
        
        var locale = Language.GetLocale();

        if (locale===undefined)
        {
            locale = GlobalAppsettings.DefaultLang;
        }

        locale = GlobalAppsettings.DefaultLang; //override
        
        this.updateLanguage(locale);
        App.subscribe("language.update", this.update.bind(this));
        App.subscribe('change.lang', this.changeLang.bind(this));
    }

    componentWillUnmount() {
        App.unsubscribe("language.update");
        App.unsubscribe('change.lang');
    }

    private changeLang(msg: string, data?: any) {
        if (data != undefined && data.lang != undefined) {
            localStorage.setItem(StorageKeys.APP_CURRENT_LANGUAGE, data.lang);
            this.updateLanguage(data.lang, false);
        }
    }

    private update(msg: string, lang: string) {
        var textdata = new Array<KeyWord>();
        let cl: Language = new Language();
        cl.loadData(textdata);
        this.setState({ current: cl });
    }

    private updateLanguage(lang: string, reload: boolean = false) {
        
        
        let currentLang = localStorage.getItem(StorageKeys.APP_CURRENT_LANGUAGE);
        if (currentLang!==undefined  && currentLang!) {
            lang = currentLang;
        }
        else
        {
            lang = GlobalAppsettings.DefaultLang;
        }

        console.info("updateLanguage:" + lang);
    
        fetch(GlobalAppsettings.BaseUrl + '/api/app/CurrentLanguage?lang=' + lang,
            { credentials: "same-origin" })
            .then(response => response.json() as Promise<ResultData<LanguageInfo>>)
            .then(result => {
                
                if (result.success) {
                    let data = result.data;
                    
                    var textdata = new Array<KeyWord>();
                    if (data.texts != undefined && data.texts.length > 0) {
                        data.texts.map(element => {
                            textdata.push({ key: element.key, value: element.value });
                        });
                        let cl: Language = new Language();
                        cl.loadData(textdata);
                        this.setState({ current: cl }, () => {
                            localStorage.setItem(StorageKeys.APP_CURRENT_LANGUAGE, data.language);
                        });
                        if (reload) {
                            window.location.reload();
                        }
                    }
                    else {
                        console.error("No language entries returned for language " + data.language);
                    }
                }
                else
                {
                    console.error(result.errorMessage);
                }
            }).catch(reason => {
                console.log(reason);
            });
    }

    render() {

        return (
            <LanguageContext.Provider value={this.state.current} >
                {this.props.children}
            </LanguageContext.Provider>
        );
    }
}



