
// Packages imports
import { Disclosure } from '@headlessui/react';

// Backbone imports
import { default as LogoWhiteBlue } from '../../logos/Logo_whiteblue';
import { useLanguage } from '../../language/Language';
import { Button, Text } from '@mantine/core';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands, thin, duotone } from '@fortawesome/fontawesome-svg-core/import.macro' 
import { useMediaQuery, useOs, useWindowScroll } from '@mantine/hooks';
import { useEffect, useState } from 'react';

import { useSearchParams, useLocation } from "react-router-dom";
import { joinClassNames } from '../../utilities';



export interface IGlobalProps {
    children?: any
    cta?: any
}

export const GlobalHeader = (props: IGlobalProps) => {
    const lang = useLanguage();

    const location = useLocation();
    const [scroll] = useWindowScroll();
    const [useOpacity, setUseOpacity] = useState(false);
    const isLargeScreen = useMediaQuery('(min-width: 1220px)');

    const [useBackDrop, setUseBackDrop] = useState(true);

    const os = useOs();
    const isMobile = os === "ios" || os === "android";

    useEffect(() => {
        if (!isMobile && location.pathname.length < 2 )
        {
            if (isLargeScreen) {
                if (scroll.y > 800) {
                    setUseOpacity(false);
                }
                if (scroll.y < 800) {
                    // setUseOpacity(true);
                }
            }
            else
            {
                if (scroll.y > 3000) {
                    setUseOpacity(false);
                }
                if (scroll.y < 3000) {
                    // setUseOpacity(true);
                }
            }
        }
        else
        {
            setUseOpacity(false);
        }  
    },[scroll, useOpacity, isLargeScreen, location, isMobile]);


    return (
        <>
            <div className="min-h-full">
                <Disclosure as="nav" className={joinClassNames("drop-shadow-md fixed 0 inset-x-0 top-0 z-50 bg-gray-800 transition-all duration-500"
                      ,useOpacity && "bg-opacity-0 transition-all duration-500"
                      ,useBackDrop && "backdrop-blur-md backdrop-brightness-150 bg-gray-800/75"
                    )} >
                    {({ open }) => (
                        <>
                            {/* <div className='text-white'>{scroll.y}</div> */}
                            <div className="px-4 sm:px-6 lg:px-8 ">
                                <div className="flex items-center justify-between h-16 ml-3 ">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 pt-1">
                                            <a href="/" title='home'>
                                                <LogoWhiteBlue width={130} />
                                                {/* <Text size={"xs"} color="dimmed" className='text-center'>Your Capability</Text> */}
                                            </a>
                                        </div>
                                        <div className="hidden md:block">
                                            <div className={joinClassNames("ml-20 flex items-center space-x-4",
                                                   useOpacity  && "hidden")} >
                                                {props.children}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:block">
                                        <div className="ml-4 flex items-center md:ml-6">
                                            {props.cta}
                                        </div>
                                    </div>
                                    <div className="-mr-2 flex md:hidden z-50">
                                        {/* Mobile menu button */}
                                        <Disclosure.Button  
                                         className="bg-gray-800/75 mr-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-whitefocus:outline-none ">
                                            {open ? (
                                                <FontAwesomeIcon  icon={regular("x")} className="block h-6 w-6"  aria-hidden="true" />
                                            ) : (
                                                <FontAwesomeIcon  icon={regular("bars")} className="block h-6 w-6 "  aria-hidden="true"/>
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                </div>
                            </div>

                            <Disclosure.Panel className="md:hidden">
                                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                    {props.children}
                                </div>
                                <div className="pt-4 pb-3 border-t border-gray-700">
                                    <div className="flex items-center px-5">
                                        {props.cta}
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        </>
    )
}
