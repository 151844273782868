import { duotone, solid, thin } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ActionIcon, Button } from "@mantine/core";
import { useLanguage } from "../language/Language";
import { usePubsub } from "../pubsub/usePubsub";
import { IntercomPubsubKeys } from "./Intercom";

export function SupportControl() { 
    const lang = useLanguage();
    const app = usePubsub();
    
    return (
        <ActionIcon size="xl" variant="transparent" 
            onClick={() => app.publish(IntercomPubsubKeys.Show,null)}>
            <FontAwesomeIcon icon={duotone('message-bot')} size="2x"
                 color="#4f46e5" />
        </ActionIcon>
    )

}