import { TextInput, TextInputProps, ActionIcon, useMantineTheme, Popover, NavLink } from '@mantine/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useEffect, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { useLanguage } from '../language/Language';
import { GlobalAppsettings } from '../../GlobalSettings';
import { SearchItem, SearchResult } from './api';

export interface  SearchProps extends TextInputProps {
    endpoint: string
    contentId?: string
    onView?: (item: SearchItem) => void
    className?: any
    filter?: string
}

export function SearchBox(props: SearchProps) {
  const theme = useMantineTheme();
  const lang = useLanguage()

  const [value, setValue] = useState('');
  const [searchVal] = useDebouncedValue(value, 200);
  const [opened, setOpened] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResult>();

  useEffect(() => {
    if (searchVal.length > 0) {
      search(searchVal);
    }
  }, [searchVal]);

  const search = async (searchText: string) => {
    fetch(GlobalAppsettings.BaseUrl + props.endpoint + '?searchText=' + searchVal + '&contentId=' 
        + props.contentId + "&locale=" + lang.locale + "&filter=" + props.filter)
        .then((response) => response.json())
        .then((data: SearchResult) => {
          setSearchResults(data);
          console.info(data);
          setOpened(true);
        });
  }

  return (
    <Popover opened={opened} onChange={setOpened} shadow="lg" classNames={props.className}
        width={600}
        >
      <Popover.Target>

      <TextInput
          leftSection={<FontAwesomeIcon icon={regular("magnifying-glass")} />}
          radius="xl"
          size="md"
          value={value}
          onChange={(event) => setValue(event.currentTarget.value)}
          
          rightSection={
            <ActionIcon size={32} radius="xl" color={theme.primaryColor} variant="filled"
              onClick={() => search(searchVal)}>
              <FontAwesomeIcon icon={regular("arrow-right")} />
            </ActionIcon>
          }      
          rightSectionWidth={42}
          {...props}
        />
      </Popover.Target>

      <Popover.Dropdown >
         {searchResults && searchResults.items && searchResults?.items.map((item, index) => {
            return (<>
              <NavLink label={item.title} 
                rightSection={<FontAwesomeIcon icon={regular("arrow-right")} 
                 />}
                onClick={() => {
                  if (props.onView!=null)
                  {
                    try {
                      props.onView(item);
                      setOpened(false);  
                    } catch (error) {
                      console.error(error);
                    }
                  }
                }}
                />
            </>)
         })}
         {searchResults && searchResults.items && searchResults?.items.length == 0 && 
          <div className="p-4 text-gray-600 font-light text-sm">
            <FontAwesomeIcon icon={duotone('tree-palm')} size="2x"/>
            {lang.Text("No results found")}
          </div>}
      </Popover.Dropdown>
    </Popover>
    
  );
}