import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paper, Title, Text, Button, Alert, Center, Loader, Group, Container, Accordion, Stack } from '@mantine/core';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useSWR from 'swr';

import { ResponseData, ResultBase, ResultData } from '../../common/api/common';
import { Invitation } from '../../common/api/Invitation';

import { Organisation } from '../../common/api/Organisation';

import { renderMarkdown } from '../../common/content/RenderMarkdown';
import { useContent } from '../../common/content/useContent';
import { fetcher, Http } from '../../common/Fetcher';
import { useLanguage } from '../../common/language/Language';
import { GlobalSecurity } from '../../common/security/GlobalSecurity';


export interface GetInvitationData  {
    organisation: Organisation;
    invitation: Invitation;
    isValid: boolean;
    notValidReason: string;
    needsToCreateOrg: boolean;
    isSameOrg: boolean;
}

const InvitePage = () => {
    const lang = useLanguage();
    const { orgId } = useParams();
    const { invitationId } = useParams();

    const { isLoggedIn, login, userInfo, userProfile, organisation, accessToken } = GlobalSecurity();
    
    const hasInvitation = React.useMemo(() => {
       console.log("Invitation params -> ",orgId, invitationId);
       let state = (orgId != null && orgId != '' 
            && orgId !== undefined) 
            && (invitationId !== undefined 
                && invitationId !== ''
                 && invitationId != null);
        return state;

    }, [orgId, invitationId]);

    const url = '/api/invitation/getInvitationInfo?invitationId=' + invitationId + '&orgId=' + orgId;
    
    const { data: invitationData, 
            error: invitationError, 
            isLoading, isValidating } = useSWR<ResponseData<GetInvitationData>>(
            hasInvitation? [
            url, accessToken]
         : null, () => fetcher(url, Http.GET, accessToken), 
         {
            refreshInterval:0, 
            onSuccess: (data) =>{
                console.log("invitation.data ->",data);
            },
            onError: (error) => {
                console.log("invitation.error ->",error);
            }
        });

    
        // const CheckAndAddOrganisationIfSame = () => {
        //     if(inviteeOrganisation && userProfile){
        //         let sameDomain: boolean = userHasSameDomainName();

        //         if(sameDomain){
        //             //Update the userprofile with the current organisation information
        //         }
        //     }
        // }

        // const userHasSameDomainName= () => {
            
        //     if(userProfile != undefined && inviteeOrganisation != undefined && inviteeOrganisation.domainNames != undefined && inviteeOrganisation.domainNames.length > 0) {
        //         let email: string = userProfile.email;

        //         let domainName = email.split('@')[1];

        //         let sameDomainName: boolean = false;

        //         inviteeOrganisation.domainNames.forEach(dn => {
        //             if(dn.toLowerCase() == domainName.toLowerCase()){
        //                 sameDomainName = true;
        //             }
        //         });

        //         return sameDomainName;
        //     }
            
        //     return false;
        // }

        // const organisationWasSet = (org: Organisation) => {
        //     setNewOrganisation(org);

        //     if(userProfile){
        //         userProfile.organisationId = org.id;
        //         userProfile.organisationName = org.name;
        //         setneedsToCreateOrg(false);
        //     }

            
            
        // }

        // const joinOrganisation = () => {
            
        //     if(userProfile == undefined || userProfile == null
        //         || inviteeOrganisation == undefined || inviteeOrganisation == null) {
        //         return;
        //     }

        //     var param = {
        //         organisationId: inviteeOrganisation.id,
        //         organisationParent: inviteeOrganisation.parent,
        //         setAsCurrent: true
        //     };

        //     fetcher(
        //         '/api/user/addOrganisationToUser',
        //         Http.POST,
        //         accessToken,
        //         param
        //     )
        //     .then((data: ResponseData<UserProfile>) => {
        //         console.log(data);
                
        //         if(data.isValid && data.success){
        //             setInvitationCompleted(true);
        //         }
        //     });
        // }

    const invitationInfo = useContent({contentID: '4Eun7dI1rbhLrOnKERdOWl', locale: lang.locale(), preview: false});
    const info = useContent({contentID: '4Eun7dI1rbhLrOnKERdOWl', locale: lang.locale(), preview: false});
    
    return(<>
    <Paper shadow={"lg"} className="mt-16 lg:p-12 sm:p-4 lg:m-16 min-h-[100vh]">
        {invitationInfo.loading || info.loading ? <Center><Loader size={'xl'} variant="dots" className='m-16'/></Center>  :
        <Container>
        <Title order={1}>{invitationInfo?.contentItem?.caption}</Title>
        <Group justify="end" grow className='my-16' align='stretch'>
            <Stack className='border-grey-200'>
                {isLoading || isValidating ? <Center><Loader size={'xl'} variant="dots" /></Center>  :
                hasInvitation ? <div>
                    {invitationData?.data.isValid ? <>
                        {/* {renderMarkdown(invitationInfo?.contentItem?.copy)} */}
                    </>: <>
                        <Alert title={lang.Text(invitationData?.errorMessage)} color="red" 
                            className=''>
                            <Text>{lang.Text(invitationData?.data.notValidReason)}</Text>
                        </Alert>
                    </>}
                
                </div>: <div>
                    <Title order={2}>{info?.contentItem?.caption}</Title>
                    {/* {renderMarkdown(info?.contentItem?.copy)} */}
                </div> }
            </Stack>
            <Container className='max-w-md '>
                <Accordion variant="filled" radius="md" defaultValue="content1">
                    <Accordion.Item value="content1">
                        <Accordion.Control><b>{lang.Text('Why did i get this invite?')}</b></Accordion.Control>
                        <Accordion.Panel> {renderMarkdown(info?.contentItem?.copy)}</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="content2">
                        <Accordion.Control><b>{lang.Text('Choose your way')}</b></Accordion.Control>
                        <Accordion.Panel> {renderMarkdown(info?.contentItem?.description)}</Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="content3">
                        <Accordion.Control><b>{lang.Text('About YouCap')}</b></Accordion.Control>
                        <Accordion.Panel> {renderMarkdown(info?.contentItem?.slug)}</Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </Container>
        </Group>
        </Container>}        
        </Paper>
    </>);
} 

export default InvitePage;