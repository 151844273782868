import * as App from 'pubsub-js';

declare global {
    interface Window {
        Intercom?: any;
        intercomSettings?: any;
    }
}

export interface IntercomUser {
    name: string
    userid: string
    email: string
}

export interface IntercomApp {
    appid: string
}

export class IntercomPubsubKeys {
    static Show: string = "intercom.open";
    static Hide: string = "intercom.hide";

    static Update: string = "intercom.update";
    static StartTour: string = "intercom.start-tour";
    static NewMessage: string = "intercom.show.newmessage";
    static SetupUser: string = "intercom.setup-user";
    static Setup: string = "intercom.setup";
    static Event: string = "intercom.event";
    static UnreadChanged: string = "intercom.unreadcount.change";
}

export class Intercom {
    constructor() {
       
        window.intercomSettings = {
            alignment: 'right',
            horizontal_padding: 8, 
            vertical_padding: 70,
            hide_default_launcher: true
          };

        App.subscribe(IntercomPubsubKeys.Update,this.update);
        App.subscribe(IntercomPubsubKeys.Show,this.show);
        App.subscribe(IntercomPubsubKeys.Hide,this.hide);

        App.subscribe(IntercomPubsubKeys.StartTour,this.startTour);
        App.subscribe(IntercomPubsubKeys.NewMessage,this.showNewMessage);

        App.subscribe(IntercomPubsubKeys.SetupUser,this.setupUser);
        App.subscribe(IntercomPubsubKeys.Setup,this.setup);

        App.subscribe(IntercomPubsubKeys.Event,this.trackEvent);

        /*
        Subscribe to 'intercom.unreadcount.change' to get badge count on laucher
        */
    }

    private showNewMessage(msg : string, data : any) {
        
        if (data!=undefined)
        {
            console.info("intercom ShowMessages -> " + data);
            if (window.Intercom!=undefined)
            {
                window.Intercom('showNewMessage', data);
            }
        }
        else
        {
            console.info("intercom ShowMessages");
            if (window.Intercom!=undefined)
            {
                window.Intercom('showNewMessage');
            }
        }
    }

    

    private startTour(msg : string, data : any) {
        
        if (data!=undefined)
        {
            console.info("Start Intercom Tour -> " + data.id);

            if (window.Intercom!=undefined)
            {
                window.Intercom('startTour', data.id);
            }
        }
    }

    private setup(msg : string, app : IntercomApp) {
        
        console.info("Setup Intercom...");
        
        if (window.Intercom!=undefined)
        {
            console.info("Boot Intercom: " + app.appid);
            
            window.Intercom('boot',
            {
                app_id: app.appid 
            });

            //TODO: add eventhandlers
            //
            //Intercom('onHide', function() { // Do stuff });
            //Intercom('onShow', function() { // Do stuff });
            window.Intercom('onUnreadCountChange', function(unreadCount: number) {
                App.publish(IntercomPubsubKeys.UnreadChanged,{count: unreadCount});
            });
        }
    }

    private setupUser(msg : string, user : IntercomUser) {
        if (window.Intercom!=undefined) {
            window.Intercom('update',{
                name: user.name,
                user_id: user.userid,
                email: user.email
            });
        }
    }

    private trackEvent(msg : string, data : any) {
        if (window.Intercom!=undefined) {
            window.Intercom('trackEvent', data);
        }
    }    

    private update(msg : string, data : any) {
        if (window.Intercom!=undefined) {
            window.Intercom('update');
        }
    }

    private show(msg : string, data : any) {
        if (window.Intercom!=undefined) {
            window.Intercom('show');
        }
    }

    private hide(msg : string, data : any) {
        if (window.Intercom!=undefined) {
            window.Intercom('hide');
        }
    }

}