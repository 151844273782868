/* eslint-disable react-hooks/exhaustive-deps */


// Backbone imports
import { GlobalAppsettings } from '../GlobalSettings';


export enum Http {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
    PATCH = 'PATCH'
} 

const getRequestInit = (method: Http, headers: Headers, payload?: string, isFile?: boolean) => {
    switch (method) {
        case 'GET':
            return {
                method: 'GET',
                credentials: 'same-origin',
                headers: headers
            } as RequestInit;
        case 'POST':
            return {
                method: 'POST',
                body: isFile ? payload : JSON.stringify(payload),
                headers: headers
            } as RequestInit;
        case 'PATCH':
            return {
                method: 'PATCH',
                body: JSON.stringify(payload),
                headers: headers
            } as RequestInit;
        case 'DELETE':
            return {
                method: 'DELETE',
                body: JSON.stringify(payload),
                headers: headers
            } as RequestInit;
    }
}

const getHeaders = async (token?: string, isFile?: boolean) => {
    if (token!==undefined) {
        const bearer = `Bearer ${token}`;
        if (isFile) {
            return new Headers({
                'Authorization': bearer
            });
        }
        return new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': bearer
        });
    } else {
        if (isFile) {
            return new Headers();
        }
        return new Headers({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        });
    }
}

export const fetcher = async <T = any>(
    url: string,
    method: Http,
    token?: string, 
    payload?: any,
    isFile?: boolean,
): Promise<T> => {
    let header = await getHeaders(token, isFile);
    let requestInit = getRequestInit(method, header, payload, isFile);
    
    //console.log('fetcher ->', url, method, token, payload, isFile);

    if (url.startsWith('https://')) {
        return fetch(url, requestInit).then((r: Response) => r.json());
    }   
    else
    {
        return fetch(GlobalAppsettings.BaseUrl +  url, requestInit).then((r: Response) => r.json());
    }
};
