
import {  useState } from 'react';

import useSWR from 'swr';
import ReactMarkdown from 'react-markdown';

// Backbone imports
import { Language, useLanguage } from '../language/Language';
import { Skeleton, Space, TypographyStylesProvider } from '@mantine/core';
import { fetcher, Http } from '../Fetcher';
import { ContentItem } from './api/Content';
import remarkGfm from 'remark-gfm';
import { IContentProps } from './ContentPage';
import { renderMarkdown } from './RenderMarkdown';


export const ContentBase = (props: IContentProps = {contentType: 'contentful', showLoading: false}) => {
    const lang = useLanguage();
    const locale = Language.GetLocale();



    const { data, isLoading: loading } = useSWR<ContentItem>(
           () => '/api/content/getcontent?id=' + props.contentID + '&locale=' + locale
            // + '&preview=false'  
        , fetcher, {
            refreshInterval: 0, 

            keepPreviousData: true
        });
    
    return (
        <div style={props.style} className={props.className}>
            {loading ? <>
                <Skeleton height={50} circle mb="xl" />
                <Skeleton height={8} radius="xl" />
                <Skeleton height={8} mt={6} radius="xl" />
                <Skeleton height={8} mt={6} width="70%" radius="xl" />
                <Space h="md" />
                <Skeleton height={8} radius="xl" />
                <Skeleton height={8} mt={6} radius="xl" />
                <Skeleton height={8} radius="xl" />
                <Skeleton height={8} mt={6} radius="xl" />
                <Skeleton height={8} radius="xl" />
                <Skeleton height={8} mt={6} radius="xl" />
                <Skeleton height={8} mt={6} width="70%" radius="xl" />

            </> : <></>}
            {renderMarkdown(data?.copy,data?.title + ".copy")}
                {props.children}
            
            {data?.slug ?
            <div className='py-6'>
                {renderMarkdown(data?.slug,data?.title + ".slug")}
            </div>
            : <></>}
        </div>
    );
}