import { useState } from "react";
import { useLanguage } from "../language/Language";

import { Drawer, Button, Group, Switch, ActionIcon} from '@mantine/core';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useContent } from "../content/useContent";
import { renderMarkdown } from "../content/RenderMarkdown";
import { InfoDrawer } from "../panels/InfoDrawer";

import * as App from 'pubsub-js';
import { useDisclosure } from "@mantine/hooks";

declare global {
  interface Window {
      UserWay?: any;
  }
}

document.addEventListener("userway:init_completed", function(event:any) {
  var instance = event.detail.userWayInstance;
  // instance.changeWidgetLanguage('sv');
  instance.iconVisibilityOff();
});




export const AccessabilityControl = () => {
    const lang = useLanguage();

    const [opened, { open, close }] = useDisclosure(false);
   
    const ShowWidget = () => {
      if (window.UserWay!==undefined)
      {
        window.UserWay.iconVisibilityOn();
        window.UserWay.widgetOpen();
        App.publish('accessability:show');
      }
    }

    const ToogleWidget = () => {
      if (window.UserWay!==undefined)
      {
        window.UserWay.widgetToggle();
      }
    }

    const ShowWidgetBtn = () => {
      if (window.UserWay!==undefined)
      {
        window.UserWay.iconVisibilityOn();
      }
    }

    const {contentItem: content, loading} = useContent({contentID: "5MVvWu95D6OyVEAtm9Lw19",locale: lang.locale()})
    
    return (<>

    <ActionIcon size="xl" variant="transparent"
          onClick={() => open()}>
        <FontAwesomeIcon icon={duotone('universal-access')} color="#4f46e5" size="2x" />
    </ActionIcon>

    
    
     <Drawer
        opened={opened}
        onClose={close}
        title={<Group grow>
          <FontAwesomeIcon icon={duotone('universal-access')} color="#4f46e5" size="3x" />
        </Group>}
        overlayProps={{ opacity: 0.5, blur: 4 }}
        size="xl"
      >
      <div className="p-4">
        <div>
          {renderMarkdown(content?.copy)}
        </div>
        <div className="my-12">
           <Button onClick={() => ShowWidget()}>{lang.Text('Show accessability Widget')}</Button>
        </div>
        <div>
          {renderMarkdown(content?.slug)}
        </div>
      </div>
      </Drawer>
    </>)
}