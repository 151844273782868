
import useSWR from "swr";
import { fetcher, Http } from "../Fetcher";
import { GlobalAppsettings } from "../../GlobalSettings";
import { useLanguage } from "../language/Language";
import { ContentItem } from "./api/Content";

export interface IContentItemProps {
    contentID: string
    locale?: string
    preview?: boolean
}

export const useContent = (props?: IContentItemProps) => {
    const lang = useLanguage();

    const { data, isLoading } = useSWR<ContentItem>(
    props!==undefined ? ['/api/content/getcontent?id=' + props?.contentID + '&locale=' + props?.locale + '&preview=false'] : null,     
    () => fetcher('/api/content/getcontent?id=' + props?.contentID + '&locale=' + props?.locale + '&preview=false', Http.GET)
    , {
        refreshInterval: 0,
        keepPreviousData: true
    });

    const getContentItem = ( contentID: string, locale? : string, preview?: boolean) : Promise<ContentItem>  => {
        let l = locale ? locale : lang.locale();
        
        if (contentID === null || contentID === undefined || contentID === "") 
            return new Promise<ContentItem>((resolve, reject) => reject("ContentID is null"));
    
        return fetch(GlobalAppsettings.BaseUrl + '/api/content/getcontent?id=' + contentID + '&locale=' + l
        + '&preview=' + preview, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            }
          })
          .then((response) => response.json());
    }

    return {
        getContentItem,
        contentItem: data,
        loading: isLoading,
    }
}

