import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Group, Text, Image, Indicator, ScrollArea, SimpleGrid, Space, Stack, useMantineTheme, Title, TypographyStylesProvider, Button, Code, PasswordInput, Stepper, TextInput, Checkbox, Anchor, Tabs, Center, Paper, CopyButton, Loader, Alert, Input, Transition, ColorInput, Chip } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useForceUpdate, useLocalStorage, useOs, useSessionStorage } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {  useContent } from "../../common/content/useContent";

import { useLanguage } from "../../common/language/Language";
import { usePubsub, useSubscribe } from "../../common/pubsub/usePubsub";
import { ProductItem } from "./api/Products";

import QRCode from "react-qr-code";
import useSWR from "swr";
import { fetcher, Http } from "../../common/Fetcher";
import { CheckoutCart, Voucher } from "./api/Store";
import { ResponseData} from "../../common/api/common";
import { GlobalAppsettings } from "../../GlobalSettings";
import { renderMarkdown } from "../../common/content/RenderMarkdown";


export interface CheckoutProps {
}

interface TeamMember 
{
  name: string, 
  email: string
}


export const Checkout = ({}: CheckoutProps) => {
    const lang = useLanguage();
    
    const [loading, setLoading] = useState(false);
    
    const os = useOs();
    const isMobile = os === "ios" || os === "android";

    const [queryParameters] = useSearchParams();
    
    const getCurrentBooking = () : string | undefined => {
      let id = queryParameters.get("id"); 
      if (id!==null) {
        console.info("Param -> " + id);
        return id;
      }
      else
      {
        return undefined;
      }
    }

    const [currentBookingId, setCurrentBookingId] = useState<string | undefined>(getCurrentBooking());

    const app = usePubsub();
    const navigate = useNavigate();
    const forceUpdate = useForceUpdate();

    const [activeStep, setActiveStep] = useState(0);

    const [error,setError] = useState<boolean>(false);
    const [errorMsg,setErrorMsg] = useState<string>();
   
    const [checkout,setCheckout] = useState<boolean>(false);


    const [completed, setCompleted] = useState<boolean>(false);

    const prevStep = () => setActiveStep((current) => (current > 0 ? current - 1 : current));

    const [total, setTotal] = useState(0);
    const [items, setItems] = useLocalStorage<ProductItem[]>(
        { key: "yc-enterprise-shoppingcart", defaultValue: new Array<ProductItem>(),
        getInitialValueInEffect : false          
    });
    const [currency, setCurrency] = useState<string>('GBP');

    const [isTeamBooking, setTeamBooking] =  useLocalStorage<boolean>({ key: "checkout-team", defaultValue: false }); 
                                          // useState<boolean>(false);
        
    const [current, setCurrent] = useLocalStorage<CheckoutCart | undefined>(
        { key: "yc-enterprise-checkout", defaultValue: undefined,
        getInitialValueInEffect : false          
    });

    const [voucherCode, setVoucherCode] = useState<string>('');
    const [voucherOk, setVoucherOk] = useState<boolean>(false);
    const [voucherLoading, setVoucherLoading] = useState<boolean>(false);
    const [voucherError, setVoucherError] = useState<boolean>(false);
    const [voucherErrorMsg, setVoucherErrorMsg] = useState<string>('');
    const [newTotal, setNewTotal] = useState(0);

    const [termsLink, setTermsLink] = useState<string>("/terms");

    useEffect(() => {
      if (voucherCode.length===0) {
        setVoucherError(false);
        setVoucherErrorMsg('');
      }
    }, [voucherCode]);

    const form = useForm({
      initialValues: {
          email: '',
          termsOfService: false,
          firstname: '',
          lastname: '',
          // team: [
          //    { firstname: '', lastname: '', email: '', productId: '', product: '' },
          // ],
        },

        validate: (values) => {
            if (activeStep === 0) { 
                return { 
                    email: /^\S+@\S+$/.test(values.email) ? null : lang.Text('Invalid email'),
                    termsOfService: values.termsOfService ? null : lang.Text('You must accept terms of service')
                };
            }

            if (activeStep === 1 && !isTeamBooking)  { 
              return { 
                firstname: values.firstname.length > 0 ? null : lang.Text('Firstname is required'),
                lastname: values.lastname.length > 0 ? null : lang.Text('Lastname is required'),
              }
            }
            
            return {};
        },
      });
    
    const info = useContent({contentID: '6tabTG9uEVvL0OZEgqAWU0', locale: lang.locale(), preview: false});
    const step1 = useContent({contentID: '4KksrO7aOUiQaflrUhFnL4', locale: lang.locale(), preview: false});
    const step2 = useContent({contentID: '2SAvEsxzsB5MprBvvlNWIq', locale: lang.locale(), preview: false});
    const step2team = useContent({contentID: '3u6aOV4xhnXjvzCsFaXey6', locale: lang.locale(), preview: false});
    const step3 = useContent({contentID: 'hAcy1TRd0MvRkhulbY2Qq', locale: lang.locale(), preview: false});
    const step4 = useContent({contentID: '6CCsVf7rfrnZeafM1NyUJF', locale: lang.locale(), preview: false});
    
    const updateTotal = (productItems: ProductItem[]) => {
        var total = 0;
        productItems?.forEach(p => { total = total + (p.count * p.price); });
        setTotal(total);
    }

    useEffect(() => {
        if (items!==undefined && items.length>0)
        {
            setItems(items);
            if (items.length>0) {
              setCurrency(items[0].currency);
              setTermsLink(items[0].termsUrl);
              console.info("Terms link: " + items[0].termsUrl);
            }
            updateTotal(items);
            // updateTeam();
            forceUpdate();
        }
    }, [items]);

    useSubscribe('cart.update', (msg, product:  ProductItem) => {
      if (items !== undefined) { 
        updateTotal(items);
        forceUpdate();
      }
    });

    const getOnSale = (onSale?: string): boolean => {
      if (onSale!==undefined && onSale!==null && onSale.length>0) {
          return false;
      }
      return true;
    }

    const hasBooking = currentBookingId!==undefined && currentBookingId!==null && currentBookingId.length>0;

    const { data } = useSWR<ResponseData<CheckoutCart>>(hasBooking 
        ? '/api/portal/getbooking?id=' + currentBookingId : null,
         fetcher, {
      refreshInterval: 2000, 
      onSuccess: (data) => {
          setLoading(false);
      },
      onError: (error) => {
          console.error(error);
          setLoading(false);
      },
  });
  
  useEffect(() => {
      if (data?.success) {
          setCurrent(data.data);
          console.info("Checkout data:", data.data);

          if (data.data.isConfirmed)
          {
              setActiveStep(3);
              setCompleted(true);
              setCurrent(undefined);
              setCurrentBookingId(undefined);
              setItems([]);
          }
      }
  }, [data]);

  const nextStep = () =>
  setActiveStep((current) => {
    if (form.validate().hasErrors) {
      return current;
    }
    else
    {
      if (current === 1) {
        // checkout
        setLoading(true);
        setCheckout(true);
      }
    }
    return current < 2 ? current + 1 : current;
  });

  useEffect(() => {
   if (checkout) {
    let booking: CheckoutCart = {
      contactFirstname: form.values.firstname,
      contactLastname: form.values.lastname,
      contactEmail: form.values.email,
      totalPrice: total,
      voucherCode: voucherCode
    }

    if (items !== undefined) { 
      booking.products = items.map((p: ProductItem) => {return p});

      if (booking.participants===undefined || booking.participants.length===0) {
        booking.participants = [];
        booking.participants.push({firstname: form.values.firstname, lastname: form.values.lastname,
          email: form.values.email, productId: items[0].sys.id});
      }
    }

    console.info("checkout...");
    console.info(booking);
    
    fetch(GlobalAppsettings.BaseUrl + "/api/portal/checkout", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(booking),
    })
      .then((response) => response.json())
      .then((data: ResponseData<CheckoutCart>) => {
        console.info("checkout response:");
        console.info(data);
      
        if (data.success) {
          setLoading(false);
          setCheckout(false);
          setError(false);
          setCurrent(data.data);
          setCurrentBookingId(data.data.id);
        }
        else
        {
          setError(true);
          setErrorMsg(data.errorMessage);
          setLoading(false);
          setCheckout(false);
          setCurrent(undefined);
          setCurrentBookingId(undefined);
        }
      });
    }
  }, [checkout]);

  const applyVoucher = () => {
    if (voucherCode!==undefined && voucherCode.length>0) {
      setVoucherLoading(true);
      setVoucherOk(false);
      fetch(GlobalAppsettings.BaseUrl + "/api/portal/checkvoucher", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          {
            voucherCode: voucherCode, 
            products: current?.products?.map((p: ProductItem) => {return p})
          }),
      })
        .then((response) => response.json())
        .then((data: ResponseData<Voucher>) => {
          console.info("apply voucher response:");
          console.info(data);
        
          if (data.success && data.data.isValid) {
            setVoucherError(false);
            setVoucherErrorMsg("");
            setVoucherLoading(false);
            setVoucherOk(true);
            setNewTotal(data.data.newPrice);
          }
          else
          {
            setVoucherError(true);
            setVoucherOk(false);
            setVoucherErrorMsg(data.data.notValidReason);
            setVoucherLoading(false);
          }
        }).catch((error) => {
            setVoucherError(true);
            setVoucherOk(false);
            setVoucherErrorMsg(error.message);
            setVoucherLoading(false);
        });
    }
  }

  const hasItems = items && items.length > 0;
    
  return (<>
     {error ? <Alert icon={<FontAwesomeIcon icon={duotone('shield-exclamation')} size="2x"/>} 
            title={<Title order={4}>{lang.Text('Something needs your attention')}</Title>} color="red" radius="md">
            <Text>{errorMsg}</Text>
    </Alert> : <></>}

    {completed ? 
      <>
      <Transition mounted={completed} transition="fade" duration={1000} timingFunction="ease">
          {(styles) => <div style={styles}>
          <Center className="pt-6"><FontAwesomeIcon color="green" icon={duotone('check-circle')} size={"5x"}/></Center>
          <Center>
            <div className="py-4 text-center">
            {renderMarkdown(step4.contentItem?.copy)}
            </div>
          </Center>
        </div>}
      </Transition>
    </>:
    <SimpleGrid spacing={0} cols={{xs: 1, lg: 2, xl: 3}}>
    <ScrollArea  offsetScrollbars>      
      <Stack className="px-2">
      {hasItems ? 
        <Stack className='pt-2'>
           <Title className="py-4">{info.contentItem?.caption}</Title>  
          
          <div className="py-2">
              {renderMarkdown(info.contentItem?.copy)}
          </div>
            {items.map((item: ProductItem, index: number) => (
            <Grid className="">
                <Grid.Col span={2}>
                    <Image radius="md" src={item.media.url}/>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Text>
                        <span className='font-bold pr-2'>{item.count} x</span>
                        {item.caption}</Text>
                </Grid.Col>
                <Grid.Col span={2}>
                    <Indicator disabled={getOnSale(item.onSale)} 
                    color="red" style={{zIndex: '1'}}
                    label={item.onSale} inline size={16} position='bottom-end'>
                    <Text size={'sm'}>
                        {lang.Text("Price")}:
                        <Text inherit component="span" className='pl-1 text-xl'>
                        {item.price*item.count} </Text> 
                        <Text inherit component="span" color={'dimmed'}>{item.currency}</Text>
                    </Text>
                    </Indicator>
                </Grid.Col>
            </Grid>
            ))}
        </Stack>
        :<>
        <Text>{lang.Text('Your Shopping Cart is empty.')}</Text>
        <Button variant="subtle" onClick={() => navigate('/store')}>{lang.Text('Add items to cart')}</Button>
        </>} 
        {hasItems ? <>
        <div className="py-4">
          <Group grow justify="start">
              <Group className="py-4">
                <Stack>
                <div className="h-8"></div>
                {/* <Button disabled={activeStep>0} variant="subtle" leftSection={<FontAwesomeIcon icon={duotone('shopping-bag')} size="2x"/>}
                  className="font-normal "
                  onClick={() => navigate('/store')}>{lang.Text('Back to Store')}</Button> */}
                <Button disabled={activeStep>0} variant="subtle" rightSection={<FontAwesomeIcon icon={duotone('shopping-cart')} size="2x"/>}
                  className="font-normal "
                  onClick={() => app.publish('cart.open', null)}>{lang.Text('Open Cart')}</Button>    
                </Stack>
              </Group>
            <Stack>
              <Group justify="start">
                  <Text className="font-bold">{lang.Text('Total amount')}:</Text>
                  <Text size={'xl'}>
                      <span className={voucherOk ? "line-through": ""}>{total}</span>
                      {voucherOk ? <span className="px-2 text-2xl text-green-500">={newTotal}</span>:<></>}
                      <Text inherit component="span" className='text-xs px-2' color={'dimmed'}>{currency}</Text>
                  </Text>
              </Group>
              <Input.Wrapper
                id="voucher"
                error={voucherErrorMsg}
                
                >
                <Input
                  id="voucher"
                  leftSection={voucherOk ? <FontAwesomeIcon icon={duotone('check-circle')} color="green"/>
                    : <FontAwesomeIcon icon={duotone('block')}/>}
                  placeholder={lang.Text('Voucher')}
                  value={voucherCode}
                  onChange={(e:any) => setVoucherCode(e.target.value)}

                  //invalid={voucherError}
                  error={voucherError}
                />
              </Input.Wrapper>
              <Button variant="outline" loading={voucherLoading} onClick={() => applyVoucher()}>{lang.Text('Apply Voucher')}</Button>
            </Stack>
          </Group>
        </div>
        <div className="py-4">
          <Group grow>
          
          <div></div>
          </Group>
        </div>
        <div className="py-2">
            {renderMarkdown(info.contentItem?.slug, "checkout.slug")}
        </div>
        </>:<></>}
    </Stack>
    </ScrollArea>
    {hasItems ?
    <Stack className="py-6">
    <Stepper active={activeStep} size={'sm'} >
        <Stepper.Step label={lang.Text("Email & Terms")} description={''}>
            <div className="py-2">
            {renderMarkdown(step1.contentItem?.copy)}
            </div>
            <TextInput label="Email" placeholder="Email" {...form.getInputProps('email')}  mt="md" />
            <Checkbox
                mt="md"
                label={<>{lang.Text('I accept')}{' '}
                <Anchor size="sm" href={termsLink} target="_blank">
                  {lang.Text('terms and conditions')}
                </Anchor>
                </>}
                {...form.getInputProps('termsOfService', { type: 'checkbox' })}
            />
             
            <div className="py-6">
            {renderMarkdown(step1.contentItem?.slug)}
            </div>
        </Stepper.Step>
        <Stepper.Step label={lang.Text("Personal info")} description={''}>
            
            <Tabs defaultValue={isTeamBooking ? 'second': 'first'} className="pt-4">
              <Tabs.List grow>
                <Tabs.Tab value="first" className="text-xl" onClick={() => setTeamBooking(false)}>{lang.Text("Personal info")}</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="first" pt="xs">
                <div className="py-2">
                  {renderMarkdown(step2.contentItem?.copy)}
                </div> 
                <TextInput label={lang.Text('First name')} placeholder={lang.Text('First name')} {...form.getInputProps('firstname')}  mt="md"/>
                <TextInput label={lang.Text('Last name')} placeholder={lang.Text('Last name')} {...form.getInputProps('lastname')}  mt="md"/>
                <div className="py-8">
                  {renderMarkdown(step2.contentItem?.slug)}
                </div>
              </Tabs.Panel>
              <Tabs.Panel value="second" pt="xs">
              <div className="py-2">
                  {/* {renderMarkdown(step2team.contentItem?.copy)} */}
                </div> 
                  <DragDropContext
                    onDragEnd={({ destination, source }) =>
                      form.reorderListItem('team', { from: source.index, to: destination ? destination.index : 0 })
                    }
                  >
                    <Droppable droppableId="dnd-list" direction="vertical">
                      {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          {/* {team} */}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                <div className="py-8">
                  {renderMarkdown(step2team.contentItem?.slug)}
                </div>
              </Tabs.Panel>
            </Tabs>  
        </Stepper.Step>
        <Stepper.Step label={lang.Text("Payment")} description={''}>
          {loading ? 
          <Group justify="center">
            <Loader className="h-[400px]" variant="dots" size={"xl"} />
          </Group>:
          <Group grow justify="start" className="p-8">
            {voucherOk ? <></>:
            <Stack className="py-2" justify={"flex-start"}>
              <div>
                {renderMarkdown(step3.contentItem?.copy)}
              </div>
              {current?.payment!==undefined ?<>
              <div className="py-2">
                  <Button leftSection={<FontAwesomeIcon icon={duotone('external-link')}/>} 
                    color="green" size="lg" component="a" target={"_self"} href={current?.payment?.paymentLink}>
                      {lang.Text('Open Payment')}
                   </Button>
               </div>
               <div className="py-2">
               {current?.payment!==undefined ?
               <CopyButton value={current?.payment?.paymentLink}>
                    {({ copied, copy }) => (
                      <Button leftSection={<FontAwesomeIcon icon={duotone('copy')}/>}
                        variant="subtle" color={copied ? 'teal' : 'blue'} onClick={copy}>
                        {copied ? lang.Text('Copied url') : lang.Text('Copy payment link')}
                      </Button>
                    )}
                  </CopyButton>
                  :<></>}
               </div>
               </>:<></>}
               <div className="py-2">
                {renderMarkdown(step3.contentItem?.slug)}
               </div>
            </Stack>}
            {!isMobile && current?.payment!==undefined && !voucherOk ?
            <Paper radius="xl" shadow="md" withBorder className="p-6 max-w-[250px] text-center">
              <Center>
                <Stack className="">
                <QRCode size={200}
                  value={current?.payment?.paymentLink}
                />
               <Text size={"sm"} className="p-2">{lang.Text('Scan to pay')}</Text>
               </Stack>
               </Center>
            </Paper> : <></> }
          </Group>}
          {current?.isPending ?
          <Group className="h-[50px]">
              <Loader  variant="dots" size={"xl"} />
              <Text>{lang.Text('Waiting for payment...')}</Text>
          </Group>: <></>}
        </Stepper.Step>
        
      </Stepper>
      <Stack>
          <Text color={"pink"}>{form.errors.termsOfService?.toString()}</Text>
      </Stack>
      <Group grow justify="space-between" mt="xl">
        {activeStep !== 0 && activeStep !== 3 && (
          <Button variant="default" onClick={prevStep}>
            {lang.Text('Back')}
          </Button>
        )}
        {activeStep > 1 || activeStep < 3 && <Button onClick={nextStep}>{lang.Text('Next Step')}</Button>}
      </Group>
    </Stack>:<></>} 
    </SimpleGrid>
    }
    <Group justify="end">
      <Button leftSection={<FontAwesomeIcon icon={duotone('comments-question-check')} size="2x"/>}
              className="my-6" variant="subtle" onClick={() => app.publish("intercom.show.newmessage",
              "I need help with my checkout: ")}>{lang.Text('Get Support')}</Button>
    </Group>
    </>);

}

