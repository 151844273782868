
import App from 'pubsub-js';
import { useEffect, useRef} from 'react';

export interface Subsription {
    msg: string,
    id: string,
}

export function usePubsub() {
    const _list = useRef<Subsription[]>([]);

    const subscribe = (msg: string, func: PubSubJS.SubscriptionListener<any>) : string => {
        var id = App.subscribe(msg, func);
        if (_list.current.find( d => d.id===id))
        {
            console.warn("subscribe: dublicate id:" + id);
            //TODO undubscribe?
        }
        else
        {
            _list.current.push({msg: msg, id: id});
        }
        // console.log(_list.current);
        return id;
    }

    const unsubscribe =  (msg: string) => {
        App.unsubscribe(msg);
    }

    const publish = (msg: string, data?: any) => {
        App.publish(msg,data);
    } 

    return {
        subscribe,
        unsubscribe,
        publish
    };

}

/**
 * Subscripe to a message and call a function when the message is published.
 * 
 * Usage:
   
    useSubscribe("msg.id",(data: any) => {
        //Do stuff
    });
 * 
 * @param msg The message to subscribe to.
 * @param func The function to call when the message is published.
 */

export function useSubscribe(msg: string, func: PubSubJS.SubscriptionListener<any>) {
    const id = useRef('');
    const App = usePubsub();

    useEffect(() => {
        id.current = App.subscribe(msg, func);
        return () => {
            App.unsubscribe(msg);
        }
    },[]); //no dependencys
}