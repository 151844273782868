import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Group, Badge, Divider, Avatar, Tooltip, Button, Alert, Anchor, Image, Text } from "@mantine/core";
import { useLanguage } from "../../common/language/Language";
import { GlobalSecurity } from "../../common/security/GlobalSecurity";
import { HasModule } from "../../common/security/components/HasModule";



export const OrganisationModule = () => {
    const lang = useLanguage();
    const { isLoggedIn, userProfile, organisation, accessToken } = GlobalSecurity();

    
    return (<>
          {organisation?.imageUrl ?
          <Card.Section>
            <Image src={organisation?.imageUrl} 
              alt="header" height={120} mah={180} 
              fit="cover"/>
          </Card.Section>: <></>}
            
          <Card.Section className="px-4" mt="md">
            <Group justify="space-between">
              <Text fw={700} className="text-3xl">
                {organisation?.name}
              </Text>
              <Badge size="sm">{organisation?.language}</Badge>
            </Group>
            <Text fz="sm" mt="xs">
            {organisation?.description}
            </Text>
          </Card.Section>

          <Card.Section className="px-4" mt="md">
            <Group justify="space-between">
              <Text fz="lg" fw={700}>
                {lang.Text('Contact')}
              </Text>
            </Group>
            <Text fz="sm" mt="xs">
            {organisation?.contactEmail}
            </Text>
          </Card.Section>
          <Divider variant="dashed" className="my-3 mx-4" />
          
          <HasModule module="organisation.management.users">
            <Card.Section className="px-4" >
              <Text fz="lg" fw={700}>
                  {lang.Text('Users')}
                </Text>
              <Group justify="start" className="mt-2">
                  <Avatar.Group spacing={"sm"}>
                      <Tooltip label="Alex Ramos" withArrow><Avatar alt="it's me" >AR</Avatar></Tooltip>
                      <Tooltip label="Samuel Danielsson" withArrow><Avatar color="violet" radius="xl">SD</Avatar></Tooltip>
                      <Tooltip label="Mattias Perssson" withArrow><Avatar color="cyan" radius="xl">MP</Avatar></Tooltip>
                      <Avatar>+5</Avatar>
                  </Avatar.Group>
              </Group>
              <Group justify="end" className="mt-4">
                  <Button className="" variant="subtle" color="grey"
                      leftSection={<FontAwesomeIcon  icon={duotone("user-cog")} size="xl"/>}
                      >{lang.Text('Manage users')}</Button>
                  <Button className="" variant="subtle" color="blue"
                      leftSection={<FontAwesomeIcon  icon={duotone("plus")} size="xl"/>}
                      >{lang.Text('Invite users')}</Button>
              </Group>
            </Card.Section>
          <Divider variant="dashed" className="my-3 mx-4" />
           </HasModule>
        
        {/* <Card.Section className="px-4" >
        {organisation?.notifications?.map((notification, index) => {
              if (notification.isActive)
              return (
                <Alert title={<div><FontAwesomeIcon icon={duotone('note-sticky')} size="2xl"
                  className="mr-4"/>{notification.title}</div>}
                  //  variant="outline"
                  color="yellow" className="mb-4" 
                    withCloseButton 
                    >
                  {notification.message}<br/>
                  {notification.link && 
                    <Anchor fz={"sm"} variant="link" color="blue" className="pt-4" href={notification.link}>{notification.link}</Anchor>
                    }
                </Alert>
              )
              else return <></>
          })}

          
          <Group justify="end" className="mt-4">
               
                <Button className="" variant="subtle" color="grey"
                    leftSection={<FontAwesomeIcon  icon={duotone("plus")} size="xl"/>}
                    >{lang.Text('Add note')}</Button>
            </Group>
        </Card.Section> */}

        
        
    </>)
}