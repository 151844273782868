import { Language,} from "../language/Language";
import { ActionRef } from "./api/Content";
import { useNavigate } from "react-router-dom";
import { useState} from "react";
import { useEffect } from "react";
import useSWR from "swr";
import { MenuEntry } from "./api/Menu";
import { fetcher } from "../Fetcher";
import { IContentItemProps } from "./useContent";
import { usePubsub } from "../pubsub/usePubsub";

export const useMenu= (props?: IContentItemProps) => {
    const locale = Language.GetLocale();
    const [items, setItems] = useState<ActionRef[]>();
    const [caption, setCaption] = useState<string>();
    const [description, setDescription] = useState<string>();

    const app = usePubsub();
    
    const { data } = useSWR<MenuEntry>(
        () => '/api/content/getmenu?contentId=' + props?.contentID + '&locale=' + locale
        + '&preview=false'
    , fetcher, {
        refreshInterval: 0,
        keepPreviousData: true
    });

    useEffect(() => {
      if (data!==undefined)
      {
        setItems(data.items);
        setCaption(data.caption);
        setDescription(data.description);
      }
    },[data]);

    let navigate = useNavigate();

    const itemClick = (action: ActionRef) => {
        if (action.messageId!=undefined && action.messageId.length > 0) {
            let data = null;
            if (action.data!==undefined && action.data.length > 0)
            data = JSON.parse(action.data);
            app.publish(action.messageId, data);
        }
        else {
            if (action.url !==undefined && action.url.length > 0) {
                navigate(action.url);
            }
            else {
                if (action.externalUrl !==undefined && action.externalUrl.length > 0) 
                window.open(action.externalUrl,"_new");
            }
        }
    }

    return {
        items: items,
        caption: caption,
        description: description,
        itemClick: itemClick
    }
}
