export class LocalStorageService{
    public static Set(key: string, data: any) {
        localStorage.setItem(key, JSON.stringify(data));
    }

    public static Get<T>(key: string) : T | undefined {
        const data = localStorage.getItem(key) || null;
        if (data !== null) {
            return JSON.parse(data);
        } else {
            return undefined;
        }
    }

    public static Remove(key: string) {
        localStorage.removeItem(key);
    }
}

export class SessionStorageService {
    public static Set(key: string, data: any) {
        sessionStorage.setItem(key, JSON.stringify(data));
    }

    public static Get(key: string) {
        const data = sessionStorage.getItem(key) || null;
        if (data !== null) {
            return JSON.parse(data);
        } else {
            return undefined;
        }
    }

    public static Remove(key: string) {
        sessionStorage.removeItem(key);
    }
}