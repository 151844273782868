import { Box } from "@mantine/core";
import { ContentBase } from "../common/content/ContentBase";
import { useLanguage } from "../common/language/Language";

export const NotFound = () => {
    const lang = useLanguage();
    
    return (
        <Box className="p-16">
            <ContentBase contentID="2cREb7AAmB1JGWtL6yFkwb" />
        </Box>
    )
}