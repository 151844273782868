import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Title, Text } from "@mantine/core";
import { useState } from "react";
import { useSubscribe } from "../../pubsub/usePubsub";
import { useOs, useMediaQuery } from "@mantine/hooks";

export const AlertModule = (props: {id: string}) => {
    
    const [alertVisible,setAlertVisible] = useState(false);
    const [alertTitle,setAlertTitle] = useState("");
    const [alertText,setAlertText] = useState("");
    const [alertColor,setAlertColor] = useState("");

    useSubscribe(props.id, (msg: string, data:any) => {
        setAlertTitle(data.title);
        setAlertText(data.text);
        setAlertColor(data.color);
        setAlertVisible(true);
    });

    const getBGColor = (color: string) => {
        switch (color) {
            case "red": return "red.1";
            case "yellow": return "yellow.1";
            case "green": return "green.1";
            case "blue": return "blue.1";
            case "dark": return "dark.1";
        }
    }

    return (<>
        {alertVisible && 
            <Alert className={"min-w-[40vw] fixed z-[1000]"}
                 variant="outline"
                 withCloseButton
                 title={ <Title order={4}>{alertTitle}</Title>}
                 color={alertColor}
                 bg={getBGColor(alertColor)}
                //  radius="md"
                 icon={<FontAwesomeIcon  icon={duotone("info-circle")} color={alertColor} size="2x" aria-hidden="true" />}
                 onClose={() => setAlertVisible(false)}
                 >
                 <Text>{alertText}</Text>
            </Alert>}
    </>);
}