/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ContentItem } from '../api/Content'
import moment, { lang } from 'moment'
import { useLanguage } from '../../language/Language'
import { usePubsub } from '../../pubsub/usePubsub'
import { ActionButton } from './ActionButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Avatar, Container, Grid, rem, SimpleGrid, Skeleton, useMantineTheme } from '@mantine/core'

export const PageByline = (props: {content?: ContentItem}) => {
  const lang = useLanguage();
  const app = usePubsub();

  if (props.content===undefined) {
    return <></>;
  }
  else
  return (
    <div className="bg-white py-5">
      <div className="flex space-x-3">
        <div className="flex-shrink-0">
          <Avatar color="#0476d9" variant={"filled"} radius="xl">YC</Avatar>
        </div>
        <div className="min-w-0 flex-1">
          <p className="text-sm font-medium text-gray-900">
            <a href="#" className="hover:underline">
              {props.content.author}
            </a>
          </p>
          <p className="text-sm text-gray-500">
            {/* <a href="#" className="hover:underline"> */}
            {moment(props.content.sys.updatedAt).format('L') + " " + moment(props.content.sys.updatedAt).format('LT')}  
            {/* </a> */}
          </p>
        </div>
        <div className="flex-shrink-0 self-center flex">
        {props.content.actions && props.content.actions.length > 0 &&
          
          <Menu as="div" className="relative z-30 inline-block text-left">
            <div>
              <Menu.Button className="-m-2 p-2 rounded-full flex items-center text-gray-400 hover:text-gray-600">
                <span className="sr-only">...</span>
                <FontAwesomeIcon  icon={regular("ellipsis-vertical")} className="block h-6 w-6"  aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-6">
                  {props.content.actions?.map((item) => 
                      <Menu.Item key={item.sys.id}>
                      {({ active }) => (
                        <ActionButton disabled={active} action={item}
                        className="my-1" 
                        />
                      )}
                    </Menu.Item>
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          }
        </div>
      </div>
    </div>
  )
}
