
import { Button, Group, Text } from '@mantine/core';
import { AccessabilityControl } from '../accessability/AccessabilityControl';
import { SupportControl } from '../intercom/SupportControl';
import { useLanguage } from '../language/Language';
import { LanguageControl } from '../language/LanguageControl';

import {default as LogoWhiteBlue} from '../logos/Logo_whiteblue';
import { GlobalPrivacy } from '../privacy/GlobalPrivacy';
import { usePubsub } from '../pubsub/usePubsub';

import { IntercomPubsubKeys } from '../intercom/Intercom';
import { useLocation, useNavigate } from 'react-router-dom';

export const PoweredBy = (props: {useWaves?: boolean, wavesPageUrl? : string}) => {
    const lang = useLanguage();
    const app = usePubsub();

    const location = useLocation();

    let useWaves = true;

    if (location.pathname===props.wavesPageUrl)
    {
        useWaves = props.useWaves ?? false;
    }

    return (<>
    {useWaves ? <div 
        style={{pointerEvents: 'none'}}
        className="z-[100] fixed left-0 bottom-0 right-0 w-screen h-[30%] bg-[url('images/footer.svg')] bg-cover bg-no-repeat bg-bottom ">

        </div> :<></>}
    <div aria-hidden="true" 
        className={useWaves ? "flex flex-row fixed left-0 bottom-0 right-0 w-screenh-16 z-[105]"
        : "flex flex-row fixed left-0 bottom-0 right-0 w-screen bg-gray-800  h-16 z-[105]"}>
       <Group 
        justify="left" 
        className="px-2 mb-1"
       >
       <LanguageControl /> 

       <GlobalPrivacy />

       <AccessabilityControl />

       <SupportControl />
        
        <div 
            className='hidden sm:block cursor-pointer' 
            style={{position: 'fixed', right: '9px', bottom: '12px'}}>
            <div 
            // href="https://www.youcap.com" 
            onClick={() => app.publish(IntercomPubsubKeys.Show,null)}>
                <Text size='xs' className={'text-white'}>{lang.Text('Powered By')}</Text>
                <LogoWhiteBlue style={{width: '100px', paddingLeft: '7px'}}/>
            </div>
        </div>
        </Group>  
    </div>
    </>
    );
}