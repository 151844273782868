
import { Badge, Button, MantineGradient,  MantineSize } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../language/Language";
import { usePubsub } from "../../pubsub/usePubsub";
import { ActionRef } from "../api/Content";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
  }

export interface ActionButtonProps {
    disabled?: boolean
    action?: ActionRef
    className?: any
    variant?: any
    leftIcon?: any
    rightIcon?: any
    loading?: boolean
    gradient?: MantineGradient
    radius?: any
    size?: MantineSize
    fullWidth?: boolean
}

export const ActionButton = (props : ActionButtonProps) => {
    const lang = useLanguage();
    const app = usePubsub();
    let navigate = useNavigate();

    const itemClick = (action?: ActionRef) => {
        if (action===undefined) return;
        if (action.messageId!=undefined && action.messageId.length > 0) {
            let data = null;
            if (action.data!==undefined && action.data.length > 0)
            data = JSON.parse(action.data);
            
            app.publish(action.messageId, data);
        }
        else {
            if (action.url !==undefined && action.url.length > 0) {
                navigate(action.url);
            }
            else {
                if (action.externalUrl !==undefined && action.externalUrl.length > 0) 
                window.open(action.externalUrl,"_new");
            }
        }
    }

    const getVariant = (variant?: string): any => {
        switch (variant) {
            case 'default': return 'default'
            case 'subtle': return 'subtle'
            case 'filled': return 'filled'
            case 'light': return 'light'
            case 'outline': return 'outline'
            case 'gradient': return 'gradient'
            default: return 'subtle';
        }        
    } 

    return (
    <>
    {/* {props.action.badge ? 
    <Badge color="red" variant="filled" className="float-right">{props.action.badge}</Badge>
    : <></>} */}
    <Button
        variant={props.variant ? props.variant : getVariant(props.action?.variant)}
        component="div"
        radius={props.radius ? props.radius : undefined}
        disabled={props.disabled}
        onClick={() => itemClick(props.action)}
        className={props.className ? props.className : undefined}
        leftSection={props.leftIcon ? props.leftIcon : null}
        rightSection={props.rightIcon ? props.rightIcon : null}
        loading={props.loading ? props.loading : undefined}
        gradient={props.gradient ? props.gradient : undefined}
        fullWidth={props.fullWidth ? props.fullWidth : undefined}
        size={props.size ? props.size : undefined}
    >
        <span>{lang.Text(props.action?.title)}</span>
    </Button>
    </>);
    
}