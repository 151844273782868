import { useContext, useEffect, useState } from 'react';

// Packages imports
import { Paper, Space, Title, Text, Notification, Image, CheckIcon, Stack, Grid, SimpleGrid, TypographyStylesProvider, Button, ActionIcon, Indicator, Drawer, Container, Card, Group, Badge, Chip, Rating, ScrollArea, Skeleton, Affix, Transition, Dialog, TextInput, Center, CloseButton, Divider } from '@mantine/core';

// Components imports
import {  useLanguage } from '../../common/language/Language';

import { ContextualHeader } from '../../common/components/navigation/ContextualHeader';
import { ContentMenu } from '../../common/content/ContentMenu';
import { SearchBox } from '../../common/search/SearchBox';
import { ShoppingCart } from './Cart';
import useSWR from 'swr';

import { ProductItem } from './api/Products';
import { fetcher, Http } from '../../common/Fetcher';
import { InfoDrawer } from '../../common/panels/InfoDrawer';
import { usePubsub } from '../../common/pubsub/usePubsub';
import { renderMarkdown } from '../../common/content/RenderMarkdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useMediaQuery, useOs } from '@mantine/hooks';

import { Checkout } from './Checkout';
import {default as LogoWhiteBlue} from '../../common/logos/Logo_whiteblue';
import { Footer } from '../../common/content/Footer';


export const Store = (props: { 
    showFAQ? : boolean, useCheckoutDrawer?: boolean, 
    filter?: string, useFullHeader?: boolean, topBar?: JSX.Element, 
    }) => {
    const lang = useLanguage();
    // const [loading, setLoading] = useState(true);
    const app = usePubsub();

    const os = useOs();
    const isMobile = os === "ios" || os === "android";
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    const isLargeScreen = useMediaQuery('(min-width: 1220px)');
    
    const [products, setProducts] = useState<ProductItem[]>([]);

    const [currentProduct, setCurrentProduct] = useState<ProductItem>();

    const [promotion, setPromotion] = useState(false);

    const [showCheckout, setShowCheckout] = useState(false);


    const { data, isLoading: loading } = useSWR<ProductItem[]>(
        '/api/content/getproducts?contentType=product' + '&locale=' + lang.locale  + '&filter=' + props.filter
        + '&preview=' + false
    , fetcher, {
        refreshInterval: 0,
        keepPreviousData: false, 
        onSuccess: () => {
            //setLoading(false);
        },
        onError: (error: any) => {
          
            //setLoading(false);
        },
    });
    
    useEffect(() => {
        if (data) {
            setProducts(data);
        }
    }, [data]);

    const onCheckout = () => {
        setShowCheckout(true);
    }

    return (<>
    
    <ContextualHeader fullHeader={props.useFullHeader} left={<>
        {props.showFAQ &&
        <Group>
            <ContentMenu contentId='4rpyTg2P5PlcxwnHf4yzSF' 
                button={isLargeScreen ? <><FontAwesomeIcon icon={duotone('bags-shopping')} size="2x" color="#0476d9" /> 
                    <span className='pl-2'>{lang.Text('Help & Info')}</span></>
                :<FontAwesomeIcon icon={duotone('bags-shopping')} size="2x" color="#0476d9" /> } 
                />
        </Group>}
        </>} 
        center={<div className='pt-1'>
            <SearchBox placeholder={lang.Text('Search store')} 
                endpoint="/api/content/search"
                contentId="product"
                filter={props.filter ? props.filter : ''}
                onView={(item) => {
                    let p = products.find((p) => p.sys.id === item.sys.id);
                    if (p!==undefined)
                    {
                        console.log(p);
                        setCurrentProduct(p);
                        app.publish('product.view');
                    }
                }}
            />
        </div>}
        actions={<div className='pt-1'>
                <ShoppingCart onCheckout={() => {onCheckout();}}/>
        </div>} 
        topBar={props.topBar}
    />
    
    {/* <ScrollArea  offsetScrollbars className={isLargeScreen ? 'h-[100vh] ':'h-[100vh]  bg-slate-50'}> */}
    <SimpleGrid  className="mt-20 p-4 sm:p-12 lg:p-16 "
        cols={{base: 1, sm: 1, md: 2,  lg: 2, xl: 3}}
       
    >
    {loading ? <>
            <Card shadow="sm" p="lg" radius="md" className='h-[40vh] w-full'>
                <Skeleton height={100} radius="md" />
                <Skeleton height={16} mt={6} radius="xl" />
                <Skeleton height={12} mt={6} width="70%" radius="xl" />
            </Card>
            <Card shadow="sm" p="lg" radius="md" className='h-[40vh] w-full'>
                <Skeleton height={100} radius="md" />
                <Skeleton height={16} mt={6} radius="xl" />
                <Skeleton height={12} mt={6} width="70%" radius="xl" />
            </Card>
            <Card shadow="sm" p="lg" radius="md" className='h-[40vh] w-full'>
                <Skeleton height={100} radius="md" />
                <Skeleton height={16} mt={6} radius="xl" />
                <Skeleton height={12} mt={6} width="70%" radius="xl" />
            </Card>
            <Card shadow="sm" p="lg" radius="md" className='h-[40vh] w-full'>
                <Skeleton height={100} radius="md" />
                <Skeleton height={16} mt={6} radius="xl" />
                <Skeleton height={12} mt={6} width="70%" radius="xl" />
            </Card>
    </>
    : products?.map((item: ProductItem, key: number) => (
        <>
        <Card key={key} shadow="sm" p="lg" radius="md" withBorder className=''>
            <Card.Section key={key+"section1"} component="div" >
                <Image
                    src={item.media.url}
                    height={160}
                    alt=""
                    fit='cover'
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                        setCurrentProduct(item);
                        app.publish('product.view');
                        }}
                />
            </Card.Section>

            <Card.Section key={key+"section2"} className='px-4 py-2 h-20 max-h-24 mb-4'>
                <Text lineClamp={3} className='text-2xl' fw={700}>{item.caption}</Text>
            </Card.Section>

            <Card.Section key={key+"section3"} className='px-4 mt-2'>
                <ScrollArea.Autosize mah={260} 
                // style={{ height: 290 }} 
                >
                <Text lineClamp={15}> 
                    {renderMarkdown(item.description,item.courseId,'text-gray text-sm')}
                 </Text> 
                </ScrollArea.Autosize>
            </Card.Section>
            
            <Card.Section key={key+"section5"} className='m-2  bottom-4 left-4 right-4'>
            <Divider className='my-2 w-full'  variant='dotted'/>
                <Group className='my-2' justify="end">
                    <Text className='m-2 text-1xl font-bold'>
                        <Text component="span">{lang.Text("Price: ")}</Text>
                        <Text inherit component="span" >
                            {item.price} </Text> 
                            <Text inherit component="span">{item.currency}</Text>
                    </Text>
                </Group>
                <Group className="" justify="space-between" gap={0}>
                    {item.images.length > 0 ? 
                    <Image src={item.images[0].url}
                        fit="contain" 
                        className='max-h-16'
                        ></Image> : <></>}
                    <Button variant='subtle' color="blue" fullWidth mt="md" radius="md" className="font-normal underline"
                        onClick={() => {
                        setCurrentProduct(item);
                        app.publish('product.view');
                        }}>
                        {item.shortDescription}
                    </Button>
                    <Button color="blue" fullWidth mt="md" radius="md" onClick={() => {
                            app.publish('cart.add',item);
                        }} className="font-bold text-xl">
                        {lang.Text('Book Now!')}
                    </Button>
                </Group>
            {/* </div> */}
            </Card.Section>
        </Card>
        
        </>
        ))}
        {/* <div className='fixed bottom-0 right-0 h-24 p-4'>
                <a href="https://www.youcap.com">
                      <Text size='xs' color="dimmed">Powered By</Text>
                      <LogoWhiteBlue style={{width: '100px', paddingLeft: '7px'}}/>
                </a>
            </div> */}

    </SimpleGrid>
    {/* </ScrollArea> */}

    <Footer/>

    <InfoDrawer message='product.view'
        title={
        <Grid  
            className='max-h-[10vh] ' 
            justify='start'>
            <Grid.Col span={4}>
            <Image
                src={currentProduct?.media.url}
                // h={160}
                alt="Norway"
                fit="contain"
                radius={'md'}
                className='max-h-20'
            />        
            </Grid.Col>
            <Grid.Col span={8}>
            <Text lineClamp={3}
                className="text-1xl" fw={700}>{currentProduct?.caption}
            </Text>
            </Grid.Col> 
        </Grid>
        }
        position="right"
        contentClassName='h-[85vh] px-6'
        callToAction={<Button variant="filled" color="blue" 
            size='md' onClick={()=>app.publish('cart.add',currentProduct)}>
                {lang.Text('Book now!')}</Button>}
        >
            
         {renderMarkdown(currentProduct?.copy)}
         
    </InfoDrawer>

    <Drawer
          opened={showCheckout} 
          position="right"
          size='100%'
          padding={0}
          onClose={()=> setShowCheckout(false)}
          className="bg-white h-full"
          overlayProps={{opacity: 0.2, color: 'black'}}
          withCloseButton={false}
          >
        <Stack>
            <CloseButton 
                style={{position: 'fixed', right: '6px', top: '6px', zIndex: '400' }}
                onClick={() => setShowCheckout(false)} title="Close popover" size="xl" iconSize={20} />
            <ScrollArea  offsetScrollbars className={isLargeScreen ? 'h-[100vh] mb-24 p-14':'h-[100vh] pb-28 p-2'}>    
                <Checkout/>
            </ScrollArea>
        </Stack>
        <div className='fixed bottom-0 right-0 h-24 p-4'>
                <a href="https://www.youcap.com">
                      <Text size='xs' color="dimmed">Powered By</Text>
                      <LogoWhiteBlue style={{width: '100px', paddingLeft: '7px'}}/>
                </a>
            </div> 
    </Drawer>  

    <Dialog
        opened={promotion}
        withCloseButton
        onClose={() => setPromotion(false)}
        size="lg"
        radius="md"
        position={{ bottom: 80, left: 20 }}
        
      >
        <Text size="sm" style={{ marginBottom: 10 }}fw={500}>
          Subscribe to email newsletter
        </Text>

        <Group align="flex-end">
          <TextInput placeholder="hello@gluesticker.com" style={{ flex: 1 }} />
          <Button onClick={() => setPromotion(false)}>Subscribe</Button>
        </Group>
      </Dialog>

    </>);
}



