
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../language/Language";
import { usePubsub } from "../../pubsub/usePubsub";
import { ActionRef } from "../api/Content";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands, thin, duotone } from '@fortawesome/fontawesome-svg-core/import.macro' 
import { SizeProp } from "@fortawesome/fontawesome-svg-core";


export interface ActionElementProps {    
    action: ActionRef
    className?: any
    onClose?: () => void
    accentColor?: string
}

export const ActionElement = (props: ActionElementProps) => {
    const lang = useLanguage();
    const app = usePubsub();
    let navigate = useNavigate();

    const closeMenu = () => {
      props.onClose?.();
    }

    const actionClick = (action: ActionRef) => {
        if (action.messageId!=undefined && action.messageId.length > 0) {
            let data = null;
            if (action.data!==undefined && action.data.length > 0)
            data = JSON.parse(action.data);
            app.publish(action.messageId, data);
            closeMenu();
        }
        else {
            if (action.url !==undefined && action.url.length > 0) {
                navigate(action.url);
                closeMenu();
            }
            else {
                if (action.externalUrl !==undefined && action.externalUrl.length > 0) 
                window.open(action.externalUrl,"_new");
            }
        }
    }

    const getIcon = () => {
      
        let size = "2x" as SizeProp;

        if (props.action.icon!==undefined && props.action.icon.length > 0)
        switch (props.action.icon) {
          case "link": return <FontAwesomeIcon  icon={duotone("link")} size={size} />;
          case "goto": return <FontAwesomeIcon  icon={duotone("arrow-right-from-line")} size={size} />;
          case "construct": return <FontAwesomeIcon  icon={duotone("pen-ruler")} size={size} />;
          case "proctor": return <FontAwesomeIcon  icon={duotone("camera-cctv")} size={size} />;
          case "testcenter": return <FontAwesomeIcon  icon={duotone("building-shield")} size={size} />;
          case "dashboard": return <FontAwesomeIcon  icon={duotone("chart-tree-map")} size={size} />;
          case "vision": return <FontAwesomeIcon  icon={duotone("lightbulb-on")} size={size} />;
          case "company": return <FontAwesomeIcon  icon={duotone("briefcase")} size={size} />;
          case "contact": return <FontAwesomeIcon  icon={duotone("address-card")} size={size} />;
          case "support": return <FontAwesomeIcon  icon={duotone("comments-question-check")} size={size} />;
          case "accessabiliy": return <FontAwesomeIcon  icon={duotone("universal-access")} size={size} />;
          case "global": return <FontAwesomeIcon  icon={duotone("earth-europe")} size={size} />;
        }

        if (props.action.externalUrl !==undefined && props.action.externalUrl.length > 0)
        return <FontAwesomeIcon  icon={duotone("arrow-up-right-from-square")} size={size} /> 

        return <FontAwesomeIcon  icon={duotone("memo-pad")} size="2x" />
    }
    
    return <>
      <div
        key={props.action.title}
        onClick={() => actionClick(props.action)}
        
        className="cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 focus:outline-none focus-visible:ring  focus-visible:ring-opacity-50"
        >
        <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white sm:h-12 sm:w-12">
            <div className={"flex items-center justify-center rounded-lg " + props.accentColor}
            style={{
              // backgroundColor: 'aliceblue', 
              width: '48px', height:'48px'}}>
                {getIcon()}
            </div>
        </div>
        <div className="mx-4">
            <p className="text-md font-semibold text-gray-700">
            {props.action.title}
            </p>
            <p className="text-sm text-gray-500">
            {props.action.description}
            </p>
        </div>
        </div>
    </>
}