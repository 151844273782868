import { useNavigate, useParams } from "react-router-dom";
import { Module } from "../../common/api/Module";
import { useLanguage } from "../../common/language/Language";
import { regular, duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Stack, Loader, Divider, Button, Text, Paper, Title, Group, Badge, useMantineTheme, SegmentedControl, Autocomplete, Avatar, Accordion, 
     CopyButton, Drawer, ActionIcon, TextInput,  Container,  Center, Tabs, Card, Alert,} from "@mantine/core";

import { useOs, useMediaQuery, useDisclosure, useForceUpdate } from "@mantine/hooks";
import { forwardRef, useEffect, useState } from "react";
import { GlobalSecurity } from "../../common/security/GlobalSecurity";
import { DataPoint, PaymentData, PaymentOnboardingData, PaymentOnboarding } from "./api/PaymentApi";
import { useContent } from "../../common/content/useContent";
import { renderMarkdown } from "../../common/content/RenderMarkdown";
import { usePubsub } from "../../common/pubsub/usePubsub";
import { AlertModule } from "../../common/components/dashboard/Alert";
import { AuthenticatedContent } from "../../common/security/components/AuthenticatedContent";
import { Footer } from "../../common/content/Footer";


import { ErrorBoundary } from "react-error-boundary";

import { Http, fetcher } from "../../common/Fetcher";
import { GlobalAppsettings } from "../../GlobalSettings";
import useSWR from "swr";


export const PaymentOnboardingPage = (props: {module?: Module}) => {
    const lang = useLanguage();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    
    const { id } = useParams();
    const [onboardingData, setOnboardingData] = useState<PaymentOnboarding>();
    

    const {data,  mutate} = useSWR<PaymentOnboardingData>(
            id!==undefined ? ["/api/payments/OnboardOrganisation?orgid=" + id] : null,
            () => fetcher("/api/payments/OnboardOrganisation?orgid=" + id, Http.GET), {
                keepPreviousData: false,
                refreshInterval: 0,
                revalidateOnFocus: true,
                onError: (err) => {
                    console.error(err);    
                },
            },            
    );

    useEffect(() => {
        console.log("Onboarding data ->",data);

        if (data?.success){   
            setOnboardingData(data.result);
        }
        else
        {
            data?.errorMessage && console.error(data.errorMessage);
        }
        setIsLoading(false);
    }, [data]);


    const info = useContent({contentID: '6L8sCLx6ABkKpgUKNYyyu0', locale: lang.locale(), preview: false});
   

    return (<>
       <Paper className="pt-20 p-10 lg:mx-16 md:mx-2 min-h-screen" shadow="md" radius="sm" withBorder>
        <Group className="mt-4 w-full"  justify="space-between">
            <Title order={2}>{info?.contentItem?.caption}</Title>
        </Group>
        <Divider variant="dashed" className="my-4" />
        {isLoading ? <Center className="w-full h-96">
            <Loader variant="dots" size={"xl"}/></Center>:
        <>
        {onboardingData &&
        <>
        {onboardingData?.isOnboarded ? 
            <Alert color="green" title={info.contentItem?.shortDescription}>
                {renderMarkdown(info?.contentItem?.slug)}
            </Alert> :
        <div className="pt-8">
            <Group className="" grow justify="space-between">
                <div className="bg-gray-50 h-80 p-4">
                     {renderMarkdown(info?.contentItem?.copy)}
                </div>
                <Stack align="center">
                    <Button variant="filled" color="blue" size="lg" 
                        rightSection={<FontAwesomeIcon icon={duotone('external-link-square')} className="text-white" />}
                        onClick={() => window.open(onboardingData?.link, "_new")}
                        >
                        {info?.contentItem?.actions[0]?.title}
                    </Button>
                    
                    {/* <img src={info?.contentItem?.media?.url} className="h-8 mt-16"/> */}
     
                </Stack>
            </Group>
        </div>}
        </>}
        </>}
        </Paper>
    </>)

}