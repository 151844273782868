import { PropsWithChildren } from "react";
import { GlobalSecurity } from "../GlobalSecurity";
import { Alert, Loader, Title, Text, Group, Center, Button, Stack } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useLanguage } from "../../language/Language";
import { useContent } from "../../content/useContent";
import { renderMarkdown } from "../../content/RenderMarkdown";
import { usePubsub } from "../../pubsub/usePubsub";

export interface AuthenticatedContentProps {
  children: PropsWithChildren<any>;
  path?: string;
}

export const AuthenticatedContent = (props: AuthenticatedContentProps) => {
    const { isLoggedIn, login, isLoading, hasOrganisation, hasInvite } = GlobalSecurity();
    const lang = useLanguage();
    const about = useContent({contentID: 'Nc9LkLhmzFg8fQKZq1Cg1', locale: lang.locale(), preview: false});
    const app = usePubsub();
    
    if (isLoading) {
      return <Center className="min-h-[350px]">
          <Loader />
        </Center>;
    }

    if (isLoggedIn && hasOrganisation) {
      return <>{props.children}</>;
    }

    if (isLoggedIn && hasInvite) {
      //redirect to invite page
      return <></>;
    }
    
    return (<div className="min-h-[90vh] ">
  
    <div className="">
      <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
          <svg
            viewBox="0 0 1024 1024"
            className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            aria-hidden="true"
          >
            <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
            <defs>
              <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                <stop stopColor="#7775D6" />
                <stop offset={1} stopColor="#E935C1" />
              </radialGradient>
            </defs>
          </svg>
          <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            {about.contentItem?.caption}
              <br />
              {about.contentItem?.description}
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-300">
            {renderMarkdown(about?.contentItem?.copy)}
            </p>
            <Stack className="mt-4">
            <Button size="lg" onClick={() => login(props.path)}>{lang.Text('Login')}</Button>
            <div className="my-4 text-white">
              {lang.Text('Or get an special invite here:')} <Button variant="outline" size="md" color="white" className="ml-4"
                onClick={() =>
                  app.publish("intercom.show.newmessage",
                     "I like to get a VIP invite, please Contact me.")}>{lang.Text('Get invite')}</Button>
            </div>
            </Stack>
          </div>
          <div className="relative mt-18 h-80 lg:mt-8">
            <img
              className="absolute left-0 top-0 w-[57rem] max-w-none rounded-md bg-white/5 ring-1 ring-white/10"
              src={about.contentItem?.media?.url}
              alt="App screenshot"
              width={1824}
              height={1080}
            />
          </div>
        </div>
      </div>
    </div>

    
    {/* <Alert 
     color={'blue.3'}
     //bg={'blue.1'}
      className="border-1 border-slate-300 p-8 m-28"
     radius="md"
    //  icon={<FontAwesomeIcon  icon={duotone("info-circle")} color={'blue'} size="2x" aria-hidden="true" />}>
     > 
      <Title className="mb-8" order={1}>{about.contentItem?.caption}</Title>

      <Group grow>
        {renderMarkdown(about?.contentItem?.copy)}
        <Center>
            <Stack>
            <Button size="lg" onClick={() => login(props.path)}>{lang.Text('Login')}</Button>
            <div className="my-4">
              {lang.Text('Or get an special invite here:')} <Button variant="outline" size="md"
                onClick={() =>
                  app.publish("intercom.show.newmessage",
                     "I like to get a VIP invite, please Contact me.")}>{lang.Text('Get invite')}</Button>
            </div>
            </Stack>
        </Center>
      </Group>
    </Alert> */}
    </div>);
};