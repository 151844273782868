import * as React from 'react';

export interface IContextMenu {
    left?: JSX.Element
    center?: JSX.Element
    actions?: JSX.Element
    fullHeader?: boolean
    topBar?: JSX.Element
}

export const ContextualHeader = (props: IContextMenu) => {

    return (
    <header className={props.fullHeader ? "bg-white drop-shadow-md fixed inset-x-0 top-0 z-40 h-32"
                                         : "bg-white drop-shadow-md fixed inset-x-0 top-16 z-40 h-16"}>
        <div className=" mx-auto px-2 sm:px-6 lg:px-8 mt-2">
            {props.topBar ? <div className='flex flex-row h-16 max-h-16'>{props.topBar}</div>: <></>}
            <div className='flex flex-row'>
                <div className='flex place-content-start'>
                    {props.left}
                </div>
                <div className='flex-1'>
                    {props.center}
                </div>
                <div className='flex flex-row place-content-end'>
                    {props.actions} 
                </div>
            </div>
        </div>
    </header>
    )
}