import { useEffect, useState } from "react";
import { useLanguage } from "../language/Language";

import { Button, Switch, Accordion, Tabs, Title, ActionIcon, Dialog, Group, Text, TextInput, Stack, Center, Drawer, Image, Flex, BackgroundImage, SimpleGrid} from '@mantine/core';
import { ContentBase } from "../content/ContentBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

import { usePubsub, useSubscribe } from "../pubsub/usePubsub";

import * as App from 'pubsub-js';
import { useDisclosure, useLocalStorage } from "@mantine/hooks";


import { GlobalAppsettings } from "../../GlobalSettings";
import { LocalStorageService } from "../StorageService";



export interface PrivacyPrefs {
    showToUser: boolean;
    consent: boolean;
    marketing: boolean;
    tracking: boolean;
}

export const GlobalPrivacy = () => {
    const lang = useLanguage();

    const [view, { open, close }] = useDisclosure(false, {
        onClose: () => {  
            let p = { consent: true, marketing: marketing, tracking: tracking, showToUser: false };
            console.info("Save privacy prefs -> ", p);
            LocalStorageService.Set(key, p);
        }
    });

    const key = GlobalAppsettings.AppId + '-privacy-prefs';

    const [prefs, setPrefs] = useState<PrivacyPrefs | undefined>(undefined);

    const [marketing, setMarketing] = useState(true);
    const [tracking, setTracking] = useState(true);
    const [popup, setPopup] = useState(false);
    

    const acceptForUser = () => {
        close();
    }

    const acceptRequiredForUser = () => {
        setMarketing(false);
        setTracking(false);
        close();
    }

    useSubscribe('global.privacy', (msg: string, data: any) => {
        open();
    });

    useEffect(() => {
        if (prefs!==null && prefs!==undefined && prefs.showToUser)
        {
            console.log("GlobalPrivacy", prefs);
            open();
        }
    }, [prefs]);


    useEffect(() => {
        if (prefs===null || prefs === undefined) {
            let p = LocalStorageService.Get<PrivacyPrefs>(key);
            if (p!==null && p!==undefined)
            {
                setPrefs(p);
                setMarketing(p.marketing);
                setTracking(p.tracking);
            }
            else
            {
                setPrefs({ consent: false, marketing: false, tracking: false, showToUser: true });
            }
        }
    },[]);

    return (<>
    
    <ActionIcon size="xl" variant="transparent" onClick={() => open()}>
        <FontAwesomeIcon icon={duotone('shield-halved')} color="green" size="2x" />
    </ActionIcon>

    <Drawer
        opened={view}
        size="xl"
        onClose={close}
        closeButtonProps={{}}
        title={
            <Group grow>
            {/* <FontAwesomeIcon icon={duotone('fingerprint')} color="green" size="3x" /> */}
            <FontAwesomeIcon icon={duotone('shield-halved')} color="green" size="3x" />
            {/* <h1 className="text-xl font-black uppercase text-brand">{lang.Text('privacy.heading')}</h1> */}
        </Group>
        }
        >
        <Drawer.Title>
       
        </Drawer.Title>
        <div className="px-6">
        
        <Tabs defaultValue={'tab1'} >
            <Tabs.List>
                <Tabs.Tab value="tab1" ><Title order={4}>{lang.Text('Cookies')}</Title></Tabs.Tab>
                <Tabs.Tab value="tab2"><Title order={4}>{lang.Text('Privacy')}</Title></Tabs.Tab>
                <Tabs.Tab value="tab3"><Title order={4}>{lang.Text('Security')}</Title></Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="tab1" >
                <ContentBase key="privacy.tab1" contentType="contentful" contentID="1wmHrGi5fLoawFjLRS6fkg">

                <Accordion multiple defaultValue={["statistics", "marketing"]}>
                    <Accordion.Item  value="required">
                        <Accordion.Control>
                            <Switch disabled checked label={lang.Text('Required')}/>
                        </Accordion.Control>
                        <Accordion.Panel>
                            {lang.Text('Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.')}
                        </Accordion.Panel>     
                    </Accordion.Item>

                    <Accordion.Item value="statistics">
                        <Accordion.Control>
                            <Switch checked={tracking} onChange={()=> setTracking(!tracking)} label={lang.Text('Statistics')}/>
                        </Accordion.Control>
                        <Accordion.Panel>
                            {lang.Text('Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.')}
                        </Accordion.Panel>
                    </Accordion.Item>

                    <Accordion.Item value="marketing">
                        <Accordion.Control>
                            <Switch checked={marketing} onChange={()=> setMarketing(!marketing)} label={lang.Text('Marketing')}/>
                        </Accordion.Control>
                        <Accordion.Panel>
                            {lang.Text('Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.')}
                        </Accordion.Panel> 
                    </Accordion.Item>                
                </Accordion>
                </ContentBase>
                <Group grow align="end" className="mt-6">
                    <Button variant="filled" size="md" 
                            onClick={() => acceptForUser()}>{lang.Text('Accept and Close')}</Button>
                    <Button variant="filled" color="green" size="md" miw={300} 
                        onClick={() => acceptRequiredForUser()}>{lang.Text('Accept necessary and Close')}</Button>
                </Group>
            </Tabs.Panel>
            <Tabs.Panel value="tab2" >
                <ContentBase 
                    key="privacy.tab2" contentType="contentful" contentID="1NmQdKXoc3o6rBUXOGgxb2"/>
                    <FontAwesomeIcon icon={duotone('fingerprint')} color="green" size="3x" />
            </Tabs.Panel>
            <Tabs.Panel value="tab3" >
                <ContentBase 
                    key="privacy.tab3" contentType="contentful" contentID="1xxg1tsMvxXUZIelAzNPIo"/>
                    <FontAwesomeIcon icon={duotone('lock')} color="green" size="3x" />
            </Tabs.Panel>
        </Tabs>
        

    </div>
    </Drawer>

    <Dialog
        opened={popup}
        withCloseButton
        onClose={() => setPopup(false)}
        size={400}
        radius="md"
        position={{ bottom: 80, left: 20 }}
      >
        <Group grow align="start">
            <FontAwesomeIcon color="green" icon={duotone('cookie-bite')} size="6x" />
            <Stack w={300}>
                <Text size="sm" style={{ marginBottom: 10 }} >
                    {lang.Text('This site uses cookies to provide you with a great user experience. Please tell us if you accept our use of cookies.')}
                </Text>
                <Group align="flex-end">
                    
                    <Button variant="outline" onClick={() => setPopup(false)}>{lang.Text('Show me')}</Button>
                    <Button onClick={() => setPopup(false)}>{lang.Text('Agree')}</Button>
                </Group>
            </Stack>
        </Group>
      </Dialog>

    </>)
}