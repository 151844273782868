
import { GlobalSecurity } from '../GlobalSecurity';


interface IHasModuleProps {
  module: string;
  children: React.ReactNode;
}


export const HasModule = (props: IHasModuleProps) => {
    const { isLoggedIn, hasModule } = GlobalSecurity();

    const check = () => {
        if (props.module !== undefined && hasModule(props.module)) {
            return true;
        }
    }

    return (
        isLoggedIn && check() ? <>{props.children}</> : <></>
    )
}