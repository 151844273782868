import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="-2 0 230 50" {...props}>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <path
            fill="aliceblue"
            stroke="#0476d9"
            d="M32 1v10.82c0 5.65-4.91 10.61-12 12.18v17h-8V24C4.91 22.43 0 17.47 0 11.82V1h9v10.9c0 3.19 3.13 5.77 7 5.77s7-2.58 7-5.77V1zM70 14v13.3c0 7.29-7.39 13.2-16.5 13.2S37 34.59 37 27.3V14C37 6.72 44.39.81 53.5.81S70 6.72 70 14zm-8-.06c0-3.54-3.58-6.4-8-6.4s-8 2.86-8 6.4v13.43c0 3.53 3.58 6.4 8 6.4s8-2.87 8-6.4zM106.93 29.28c-1 5-3 8-9 11-5 2-9 1.75-14.34-.43s-8.66-6.49-8.66-11.21V1.28h9v27.57c0 3.16 3.16 5.71 7 5.71s7-2.55 7-5.71V1.28h9z"
          />
          <path
            fill="#0476d9"
            d="M140.62 27h9c0 7.13-7.75 12.91-17.31 12.91S115 34.13 115 27V14c0-7.13 7.75-12.91 17.31-12.91S149.62 6.87 149.62 14h-9c0-3.42-3.72-6.2-8.31-6.2S124 10.58 124 14v13c0 3.42 3.72 6.19 8.31 6.2s8.3-2.78 8.31-6.2zM188 14.08V41h-9V27h-16v14h-8V14.08C155 6.7 162.39.71 171.5.71S188 6.7 188 14.08zM179 20v-5.61c0-3.54-3.58-6.41-8-6.41s-8 2.87-8 6.41V20zM211 0c8.35 0 15.12 5.37 15.12 12S219.35 24 211 24h-6v17h-9V8h-4V0zm6.5 12c0-2.76-2.8-5-6.25-5H205v10h6.25c3.45 0 6.25-2.24 6.25-5z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
