import { Paper } from "@mantine/core";

import { useSearchParams, Path } from "react-router-dom";
import { ContextualHeader } from "../common/components/navigation/ContextualHeader";
import { ContentMenu } from "../common/content/ContentMenu";
import { useLanguage } from "../common/language/Language";
import { MyBooking } from "../modules/booking/MyBooking";

export const OnboardingPage = () => {
    const lang = useLanguage();

    const [queryParameters] = useSearchParams()

    const currentId = queryParameters.get("id");
    const currentParticipantId = queryParameters.get("part");

    return (<>
        {/* <ContextualHeader left={<>
            <ContentMenu contentId='4rpyTg2P5PlcxwnHf4yzSF' />
        </>} 
            center={<div className='pr-4'>        
            </div>}
            actions={<></>} 
        /> */}
        <Paper shadow={"lg"} className="mt-8 p-4 sm:p-6 lg:mx-16 ">
            <MyBooking id={currentId} part={currentParticipantId}></MyBooking>
        </Paper>
    </>);

}