import { Popover, Transition } from '@headlessui/react'

import { TypographyStylesProvider } from '@mantine/core'
import { Fragment, useEffect, useState } from 'react'

import useSWR from 'swr'

import { fetcher } from '../Fetcher'
import { Language, useLanguage } from '../language/Language'
import { ActionRef } from './api/Content'
import { MenuEntry } from './api/Menu'
import { ActionButton } from './components/ActionButton'
import { ActionElement } from './components/ActionElement'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular} from '@fortawesome/fontawesome-svg-core/import.macro' 
import { renderMarkdown } from './RenderMarkdown'


export const ContentMenu = (props: {contentId: string, dark?: boolean, tight?: boolean, preview?: boolean, button?: any, accentColor?: string, bgColor?:string, children?: any }) => {
    const lang = useLanguage();
    const locale = Language.GetLocale();
    const [items, setItems] = useState<ActionRef[]>();
    
    const { data } = useSWR<MenuEntry>(
      () =>  '/api/content/getmenu?contentId=' + props.contentId + '&locale=' + locale
        + '&preview=' + props.preview
    , fetcher, {
        refreshInterval: 0,
        keepPreviousData: true
    });

    useEffect(() => {
      if (data!==undefined)
        setItems(data.items);
    },[data]);

    if (data===undefined) {
      return <></>;
    }
    else {
    let isSignelitem = data?.items.length === 1;
    let item = data?.items[0];
  
      return (<>
        { isSignelitem ? 
            <ActionButton action={item} disabled={false} />
        : renderMenu(data, props, items)
        }
        </>
      )
    }
}

function renderMenu(data: MenuEntry | undefined, 
  props: { contentId: string; dark?: boolean | undefined; tight?: boolean; preview?: boolean | undefined, button?: any, accentColor?: string, bgColor?:string, children?: any  }, 
  items: ActionRef[] | undefined) {
  return (
      <Popover className="relative" key={data?.id}>
        {({ open }) => (
          <>
            <Popover.Button
              className={`
                ${open ? '' : 'text-opacity-90'} ${props.dark ? 'text-white' : 'text-black'} bg-transparent
                 group px-3 py-2 rounded-md inline-flex items-center font-medium hover:text-opacity-100 
                 ${props.dark ? 'hover:bg-transparent' : 'hover:bg-sky-50'}
                 focus:outline-none 
                 `}
            >
              {props.button ? props.button : <span className='font-medium'>{data?.caption}</span>}
              {open ? 
                 <FontAwesomeIcon  icon={regular("chevron-up")} 
                    className={`${open ? '' : 'text-opacity-0'} 
                    ${props.dark ? 'text-white' : 'text-black'}
                    mx-2 h-3 w-3 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true" />:
                <FontAwesomeIcon  icon={regular("chevron-down")} 
                    className={`${open ? '' : 'text-opacity-0'} 
                    ${props.dark ? 'text-white' : 'text-black'}
                    mx-2 h-3 w-3 group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
                />
              }
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-400 w-screen max-w-sm px-4 sm:px-4 lg:max-w-2xl xl:max-w-3xl sm:left-[-40px] md:left-[-10px]" style={{zIndex: 200}}>
                {({ close}) => (
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className={props.tight ? "relative grid gap-8 bg-white p-7 grid-cols-1" : "relative grid gap-8 bg-white p-7 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2"}>
                    {items?.map((item) => (
                      <ActionElement key={item.sys.id} action={item} onClose={() => close()} accentColor={props.accentColor ? props.accentColor : 'text-brand'}/>
                    ))}
                    {props.children? props.children : <></>}
                  </div>
                  {/* <div className="p-6 bg-sky-50 border-t border-sky-300"> */}
                  <div className={props.bgColor ? "p-6 " + props.bgColor + " border-t border-gray-200" : "p-6 bg-gray-800 border-t border-gray-200"}>
                    <div
                      className=""
                      style={{ marginTop: '-35px' }}
                    >
                      {renderMarkdown(data?.description,'','text-white font-light text-sm')}
                      
                    </div>
                  </div>
                </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
  )
}

