import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Group, Paper } from "@mantine/core";
import { useMediaQuery, useOs } from "@mantine/hooks";
import { ContextualHeader } from "../common/components/navigation/ContextualHeader";
import { ContentMenu } from "../common/content/ContentMenu";
import { useLanguage } from "../common/language/Language";

import { ShoppingCart } from "../modules/ecommerce/Cart";
import { Checkout } from "../modules/ecommerce/Checkout";


export const CheckoutPage = () => {
    const lang = useLanguage();
    const os = useOs();
    const isMobile = os === "ios" || os === "android";
    const isSmallScreen = useMediaQuery('(max-width: 768px)');
    const isLargeScreen = useMediaQuery('(min-width: 1220px)');
    
    return (<>
        <ContextualHeader left={<>
        <Group>
            <ContentMenu contentId='4rpyTg2P5PlcxwnHf4yzSF' 
                button={isLargeScreen ? <><FontAwesomeIcon icon={duotone('bags-shopping')} size="2x" color="#0476d9" /> 
                    <span className='pl-2'>{lang.Text('Help & Info')}</span></>
                :<FontAwesomeIcon icon={duotone('bags-shopping')} size="2x" color="#0476d9" /> } 
                />
        </Group>
    </>} 
        center={<div className='px-2 pt-1'></div>}
        actions={<div className='pt-1'><ShoppingCart/></div>} 
        />
        <Paper shadow={"lg"} className="my-28 p-4 sm:p-6 lg:mx-16">
            <Checkout />
        </Paper>
    </>);

}