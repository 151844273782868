

import * as Sentry from "@sentry/react";
import React from 'react';
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";


const { REACT_APP_IS_DEV } = process.env;
const { REACT_APP_BASE_URL } = process.env;
const { REACT_APP_DEFAULT_LANGUAGE } = process.env;
const { REACT_APP_AUTH_PROVIDER } = process.env;
const { REACT_APP_STRIPE_KEY } = process.env;

export class GlobalAppsettings {

  protected static api_key: string = "*l1^-;L^p1|G%956n_*Q520b";

  static AppId: string = "yc-enterprise-portal";

  static AppInsights: string = "9f260433-df6f-4f7e-9ba4-981ab26da62a";

  static IsDevelopment: boolean =  REACT_APP_IS_DEV ? (REACT_APP_IS_DEV.toLowerCase() === 'true') : false;

  static BaseUrl: string = REACT_APP_BASE_URL ? REACT_APP_BASE_URL : '';

  static InterCom: string = "vyoqj5te";

  static AuthProvider: string = REACT_APP_AUTH_PROVIDER ? REACT_APP_AUTH_PROVIDER : 'auth0';

  static DefaultLang: string = REACT_APP_DEFAULT_LANGUAGE ? REACT_APP_DEFAULT_LANGUAGE : 'en-GB';

  static StripeKey: string = REACT_APP_STRIPE_KEY ? REACT_APP_STRIPE_KEY : "pk_test_51IsRksABc38EtH9J52zPbml0d4WYIB7pkOkYZY71qmdeSNp9YkAiCbCFfj7f8FvwBVYVV8BK8uGaEfb6qFUbJNbZ00z4mii47Z";

  static Audience: string = `https://api.portal.youcap.com`;
}

const APP_USER = GlobalAppsettings.AppId + "-user";
const APP_USER_SELECTED_PROGRAM = APP_USER + "-selected-program";
const APP_LANGUAGE = GlobalAppsettings.AppId + "-language";
const APP_CURRENT_LANGUAGE = GlobalAppsettings.AppId + "-current-language";

console.info(REACT_APP_BASE_URL);
console.info(REACT_APP_DEFAULT_LANGUAGE);

export const StorageKeys = {
  APP_USER,
  APP_USER_SELECTED_PROGRAM,
  APP_LANGUAGE,
  APP_CURRENT_LANGUAGE,
};

if (GlobalAppsettings.IsDevelopment) {
  
  console.info("Development Mode");

}
else{
  //appInsights.loadAppInsights();
  //console.info("AppInsights Loaded: ", GlobalAppsettings.AppInsights);
  //appInsights.trackPageView({name: "Portal"});

  Sentry.init({
    dsn: "https://1f440ea40e00634d0e242797b962e78c@o4505855795593216.ingest.sentry.io/4505857792409600",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.2,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  console.log = function () {};
  console.info = function () {};
  console.warn = function () {};
  console.error = function (...data: any[]) {
    //appInsights.trackException({exception: new Error(data.join(' '))});
    Sentry.captureException(new Error(data.join(' ')));
  };
} 


//


export const localStorageCacheProvider = () => {
  const map = new Map(JSON.parse(localStorage.getItem(GlobalAppsettings.AppId + '-app-cache') || '[]'))
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()))
    localStorage.setItem(GlobalAppsettings.AppId + '-app-cache', appCache)
  })
  return map
}

export const sessionStorageCacheProvider = () => {
  const map = new Map(JSON.parse(sessionStorage.getItem(GlobalAppsettings.AppId + '-app-cache') || '[]'))
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()))
    sessionStorage.setItem(GlobalAppsettings.AppId + '-app-cache', appCache)
  })
  return map
}



