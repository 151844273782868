
import { useContext, useEffect, useState } from 'react';

// Packages imports
import { Paper, Space, Title, Text, Notification, Image, CheckIcon, Stack, Grid, Anchor, SimpleGrid, TypographyStylesProvider, Button, Drawer, ScrollArea, Group, Center, CloseButton } from '@mantine/core';

// Components imports
import { LanguageContext, useLanguage } from '../common/language/Language';
import { useContent } from '../common/content/useContent';
import { ActionButton } from '../common/content/components/ActionButton';
import { useMediaQuery, useWindowScroll } from '@mantine/hooks';
import { usePubsub } from '../common/pubsub/usePubsub';

import {default as LogoWhiteBlue} from '../common/logos/Logo_whiteblue';
import { renderMarkdown } from '../common/content/RenderMarkdown';
import { useMenu } from '../common/content/useMenu';

import { useNavigate } from "react-router-dom";
import { Footer } from '../common/content/Footer';
import { News } from '../common/content/News';


export type LandingProps = {
};


export const Landing = ({}: LandingProps) => {
    const lang = useContext(LanguageContext);
    const app = usePubsub();

    const hero1 = useContent({contentID: '3XiqPZxl3hDVF1bolQ9jpO', locale: lang.locale(), preview: false});
    const hero2 = useContent({contentID: '5kcY87sRr2klW8jsSXW0NB', locale: lang.locale(), preview: false});
    const hero3 = useContent({contentID: '3PTC1LGANtWFYqKyen4Wnv', locale: lang.locale(), preview: false});
    const platform = useContent({contentID: '1PcaHk7FwRu91rcMrLdHpa', locale: lang.locale(), preview: false});
    const partners = useContent({contentID: '7vjYCIQtGp0uiN6vChM0cZ', locale: lang.locale(), preview: false});
    
   
    const [showhero1,setShowHero1] = useState(true);
    const [showhero2,setShowHero2] = useState(false);
    const [showhero3,setShowHero3] = useState(false);

    const [viewHero1, setViewHero1] = useState(false);
    const [viewHero2, setViewHero2] = useState(false);
    const [viewHero3, setViewHero3] = useState(false);

    const isMobile = useMediaQuery('(max-width: 768px)');
    const isLargeScreen = useMediaQuery('(min-width: 1200px)');

    const smallDevice = useMediaQuery('(max-width: 1220px)');
    const [scroll, scrollTo] = useWindowScroll();

    useEffect(() => {
      if (scroll.y < 20) {
        setShowHero1(true);
        setShowHero2(false);
        setShowHero3(false);
      }
      // if (scroll.y > 0) {
      //   setShowHero1(true);
      // }
      if (scroll.y > 700) {
        setShowHero2(true);
        setShowHero3(false);
        setShowHero1(false);
      }
      if (scroll.y > 1540) {
        setShowHero3(true);
        setShowHero1(false);
        setShowHero2(false);
      }
    }, [scroll.y]);


    const heroContent = (opened: boolean, onClose: () => void, title?: string, children?: any, url?: string) => {
      return (
        <Drawer
        opened={opened} 
        position="right"
        //size={isMobile ? '100%' : isLargeScreen? '900px' : '80%'}
        size='100%'
        padding={0}
        onClose={onClose}
        className="bg-gray-800 h-full"
        overlayProps={{opacity: 0., color: 'black'}}

        withCloseButton={false}
        >      
      <SimpleGrid spacing={0}  className='bg-gray-800 h-full'
        cols={ {base: 1, xl: 2}}
          >
          <div className={isLargeScreen ? 'w-full h-full ': 'w-full h-[120px] '}>
            <img onClick={() => onClose()} 
              className="inset-0 h-full w-full object-cover"
              src={url}
              alt=""
            />
            <Group className={isLargeScreen ? 'fixed w-[46vw] top-8 left-8 h-28': 'hidden fixed left-16 top-1 h-28'}
              justify="right">
              <Button radius={'xl'} variant="filled" size='lg' onClick={() => 
                     app.publish("intercom.show.newmessage",
                     "I like to know more about " + title + ", please Contact me.")}>{lang.Text('Get in touch!')}
                </Button>
                {/* {isLargeScreen ? <Center>
                  <Text size={'xs'} color="dimmed">Copyright 2022 YouCap Ltd.</Text>
              </Center>:<></>} */}
            </Group>
          </div>
          <Stack  
            className={isLargeScreen ? 'w-full h-full px-12 py-4 shadow-lg' : 'w-full h-full px-6 shadow-lg'} >
              <CloseButton 
                style={{position: 'fixed', right: '6px', top: '6px' }}
                onClick={() => onClose()} title="Close popover" size="xl" iconSize={20} />
            <ScrollArea  offsetScrollbars className={isLargeScreen ? 'h-[100vh] mb-24':'h-[100vh] mb-24'}>
                {children}
            </ScrollArea>
            <div className={isLargeScreen ? 'fixed w-[48vw] bottom-0 left-0 h-24': 'fixed left-8 top-9 right-8 h-24'}>
              <Group className='py-6' align='start'>  
                <div 
                  style={{position: 'absolute', right: '30px', }}>
                  <a href="/">
                      <Text size='xs' className='text-white'>Powered By</Text>
                      <LogoWhiteBlue style={{width: '100px', paddingLeft: '7px'}}/>
                  </a>
                </div>
              </Group>
             
            </div>
          </Stack> 
      </SimpleGrid>
    </Drawer>
    )}

    const _cacheHero1Img = "https://images.ctfassets.net/2b5s0apidvye/1fllYdGvftt9Ylz3GQ7Xn7/04d79e32152109df988041dbc7bcedb4/60bede999448454d9a8f9ad1_levi-stute-mFF39sOZSgM-unsplash.jpg";
    const _cacheHero2Img = "https://images.ctfassets.net/2b5s0apidvye/2Mh0ssRpPV8lLABOEn6zSF/95f9a4c5da82755694681be9ce7ffec3/60bedead81ff697d9def8aee_claudio-guglieri-K2RH1QZdLF4-unsplash-2.jpg";
    const _cacheHero3Img = "https://images.ctfassets.net/2b5s0apidvye/3omUR8IsZCjszdVskzBtuy/bb5f835cac07456cbb94afa4255ea895/60bedeca189e9b039421db10_sahand-hoseini-RnCLtXEouJE-unsplash__1_.jpg";


    return (<>
    
    <SimpleGrid className="w-full "
      spacing={0}
      cols={{base: 1, lg: 3}}
      >
        <div className="h-screen bg-cover bg-left bg-no-repeat" 
          style={{backgroundImage: hero1.contentItem? `url('${hero1.contentItem?.media.url}')` : `url('${_cacheHero1Img}')`
          }}>
          <div 
          className={smallDevice ? 
            showhero1 ? "h-full w-full text-right text-white lg:pt-36 sm:pt-48 pt-36 p-6 ease-in-out duration-500 opacity-100 transition-opacity"
            : "h-full w-full text-right text-white lg:pt-36 sm:pt-48 pt-36 p-6 ease-in-out duration-500 opacity-0 transition-opacity"
            : "h-full w-full text-right text-white lg:pt-14 sm:pt-8 p-4 ease-in-out duration-500 opacity-0 hover:bg-gradient-to-r hover:opacity-100 transition-opacity"
          }
            >
              <Stack justify="flex-end" align="flex-end" >
                  {renderMarkdown(hero1.contentItem?.copy,'','text-white pt-20')}
              {hero1.contentItem?.shortDescription ?
                  <Button size='md' variant='outline' color="yellow" radius={'xl'} onClick={() => setViewHero1(true)}>
                    {hero1.contentItem?.shortDescription}
                  </Button>:<></>}
              </Stack>
          </div>
          </div>
          <div className="h-screen bg-cover bg-center bg-no-repeat " 
          style={{backgroundImage: hero2.contentItem? `url('${hero2.contentItem?.media.url}')` : `url('${_cacheHero2Img}')`
           }}>
              <div 
              className={smallDevice ?
                showhero2 ? "h-full w-full text-right text-white lg:pt-36 sm:pt-48 pt-36 p-6 ease-in-out duration-500 opacity-100 transition-opacity"
                : "h-full w-full text-right text-white lg:pt-36 sm:pt-48 pt-36 p-6 ease-in-out duration-500 opacity-0 transition-opacity"
                : "h-full w-full text-right text-white lg:p-14 sm:p-8 p-8 ease-in-out duration-500 opacity-0 hover:bg-gradient-to-r hover:opacity-100 transition-all hover:transform scale-105"
              }>
              <Stack justify="flex-start" align="flex-end"  >
                {renderMarkdown(hero2.contentItem?.copy,'','text-white pt-20')}
                  {hero2.contentItem?.shortDescription ?
                  <Button size='md' variant='outline' color="yellow" radius={'xl'} onClick={() => setViewHero2(true)}>
                    {hero2.contentItem?.shortDescription}
                  </Button>:<></>}
              </Stack>
          </div>
          </div>
          <div className="h-screen bg-cover bg-right-top bg-no-repeat" 
          style={{backgroundImage: hero3.contentItem? `url('${hero3.contentItem?.media.url}')` : `url('${_cacheHero3Img}')`
          }}>
              <div className={smallDevice ? 
            showhero3 ? "h-full w-full text-right text-white lg:pt-36 sm:pt-48 pt-36 p-6 ease-in-out duration-500 opacity-100 transition-opacity"
            : "h-full w-full text-right text-white lg:pt-36 sm:pt-48 pt-36 p-6 ease-in-out duration-500 opacity-0 transition-opacity"
            : "h-full w-full text-right text-white lg:p-14 sm:p-8 p-4 ease-in-out duration-500 opacity-0 hover:bg-gradient-to-r hover:opacity-100 transition-opacity"
          }>
              <Stack justify="flex-end" align="flex-end"  >
              {renderMarkdown(hero3.contentItem?.copy,'','text-white pt-20')}
                {hero3.contentItem?.shortDescription ?
                  <Button size='md' variant='outline' color="yellow" radius={'xl'} onClick={() => setViewHero3(true)}>
                    {hero3.contentItem?.shortDescription}
                  </Button>:<></>}
              </Stack>
          </div>
          </div>
      </SimpleGrid>

    <Paper shadow="sm" // className="relative"
      >
       {/* Logo cloud */}
       <div className="bg-gray-100 ">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <p className="text-center text-sm font-semibold uppercase text-gray-500 tracking-wide">
                {partners.contentItem?.caption}
              </p>
              <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
                {partners.contentItem?.images.map((partner, key) => (
                  <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 max-h-20">
                    <img className="object-contain mix-blend-darken opacity-70 saturate-0 grayscale" src={partner.url} alt={partner.title} />
                  </div>
                  ))}
              </div>
            </div>
          </div>
       

      <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div>
          <h2 className="text-base font-semibold text-brand uppercase tracking-wide">{platform.contentItem?.shortDescription}</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900">{platform.contentItem?.caption}</p>
          <div className="mt-4 text-lg text-gray-500">
          
          {renderMarkdown(platform.contentItem?.copy,'','text-gray')}
          
          </div>
        </div>
        <div className="mt-12 lg:mt-0 lg:col-span-2">
          <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
            {platform.contentItem?.actions.map((feature, key) => (
              <div key={key} className="relative">
                <dt>
                  <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.title}</p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>

    {/* <div className='p-6'> */}
    <Center>
      <Title order={2} className="p-4">{lang.Text('Latest News')}</Title>
    </Center>
    
    <News />

    {/* </div>  */}

    <Footer/>

    </Paper>

    {heroContent(viewHero1,()=> setViewHero1(false),
      hero1.contentItem?.caption
      ,
      <Stack>
        <div className='text-white font-extralight'>
          {renderMarkdown(hero1.contentItem?.slug,'','text-white')}
        </div>
      </Stack>
      ,hero1.contentItem?.media.url)}

     {heroContent(viewHero2,()=> setViewHero2(false),
      hero2.contentItem?.caption, 
      <Stack>
        <div className='text-white font-extralight'>
          {renderMarkdown(hero2.contentItem?.slug,'','text-white','white')}
        </div>
      </Stack>
      ,hero2.contentItem?.media.url)}

    {heroContent(viewHero3,()=> setViewHero3(false),
      hero3.contentItem?.caption, 
      <Stack>
        <div className='text-white font-extralight'>
          {renderMarkdown(hero3.contentItem?.slug,'','text-white')}
        </div>
      </Stack>
      ,hero3.contentItem?.media.url)}

    </> 
    );
};

